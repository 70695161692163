import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './styles.scss';
import {RouteComponentProps} from 'react-router';
import Layout from '../../../components/Layout';
import {HTMLVideoStreamElement, WebRTCUserMedia} from '../types';
import {ShareStreamOption} from '../enums';
import VertoSession from '../../../verto/VertoSession';
import videojs, {VideoJsPlayer} from 'video.js';
import 'video.js/dist/video-js.css';
import {Routes} from '../../../shared/routes';
import {INIT_VOL, IS_IN_FULLSCREEN} from '../../../shared/constants';
import Participants from './Participants';
import Chat from '../../../components/Chat';
import SideBar from './SideBar';
import PlayerControlBar from './PlayerControlBar';
import {Participant} from '../../../verto/models';
import {ReduxSelectors} from '../../../redux/shared/types';
import useBeforeUnload from '../useBeforeUnload';
import getCamParams from '../../../shared/methods/getCamParams';
import getMicParams from '../../../shared/methods/getMicParams';
import WatchPartySession, {MEDIA_VIDEO_ID} from './WatchPartySession';
import TopBar from './TopBar';
import FullscreenListeners from './FullscreenListeners';
import ChangeStream from './ChangeStream';
import ChangeFile from './ChangeFile';
import ProgressLoader from '../../../components/ProgressLoader';
import {VlrService} from '../../../services';
import AdSenseCard from '../../../components/AdSense/AdSenseCard';
import {AdSenseFormat, AdSenseSlot} from '../../../components/AdSense';
import getDisplayMedia from '../../../shared/methods/getDisplayMedia';
import {setErrorToast} from '../../../redux/actions/toastActions';
import {setInRoom} from '../../../redux/actions/inRoomActions';
import setLivingRoom from '../../../redux/actions/livingRoomActions';
import NoVideoCanvas from '../../../components/NoVideoCanvas';
import useNetworkUpSpeed from '../../../hooks/useNetworkUpSpeed';
import useApplyVideoTrackConstrains from '../../../hooks/useApplyVideoTrackConstrains';
import StreamDebugInfo from '../../../components/StreamDebugInfo';
import {
  resetStreamDebugValues,
  setStreamDebug,
  setStreamDebugReplaceSentStream,
  setStreamDebugSentStream,
  setStreamDebugVideoElement
} from '../../../redux/actions/streamDebugActions';
import { IonAlert } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { SharedStream } from '../../../shared/types';

const LivingRoom: FC<RouteComponentProps> = ({history}: RouteComponentProps) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const livingRoom = useSelector(({livingRoom}: ReduxSelectors) => livingRoom);
  const userMedia = useSelector(({userMedia}: ReduxSelectors) => userMedia);
  const inRoom = useSelector(({inRoom}: ReduxSelectors) => inRoom);

  const pageRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoStreamElement>();
  const audioRef = useRef<HTMLAudioElement>(null);
  const userMediaRef = useRef<MediaStream>();
  const screenShareRef = useRef<MediaStream | null>(null);
  const vertoSessionRef = useRef<VertoSession | null>(null);
  const noVideoTrackRef = useRef<MediaStreamTrack>();
  const participantsRef = useRef<Participant[]>([]);
  const sharedRef = useRef<{ oldValue: ShareStreamOption | null, newValue: ShareStreamOption | null }>({
    oldValue: null,
    newValue: null
  });
  const shareRef = useRef<ShareStreamOption | null>(livingRoom.share);
  const mediaRecorderRef = useRef<MediaRecorder>();
  const micMutedRef = useRef<boolean>(true);

  const [vjsPlayer, setVjsPlayer] = useState<VideoJsPlayer | null>(null);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [participantTalks, setParticipantTalks] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [showProgressLeave, setShowProgressLeave] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [showParticipants, setShowParticipants] = useState<boolean>(false);
  const [showChat, setShowChat] = useState<boolean>(false);
  const [captureStream, setCaptureStream] = useState<MediaStream | null>(null);
  const [micMuted, setMicMuted] = useState<boolean>(true);
  const [camStopped, setCamStopped] = useState<boolean>(true);
  const [gainNode, setGainNode] = useState<GainNode>();
  const [showDebugStream, setShowDebugStream] = useState<boolean>(false);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [showChangeStream, setShowChangeStream] = useState<boolean>(false);
  const [showChangeFile, setShowChangeFile] = useState<boolean>(false);
  const [videoTrack, setVideoTrack] = useState<MediaStreamTrack | null>(null);
  const [isExitAlert, setIsExitAlert] = useState<boolean>(false)
  const [isSecondaryRecalled, setIsSecondaryRecalled] = useState<boolean>(false)

  const handleNoVideoTrack = useCallback((track: MediaStreamTrack) => {
    noVideoTrackRef.current = track;
  }, []);

  // @@@
  let emptyAudioMediaStream: any = null;
  const getEmptyAudioStream = () => {
    if (emptyAudioMediaStream) {
      return emptyAudioMediaStream;
    }

    // Create a silent oscillator as the audio source
    const audioContext = new AudioContext();
    const destination = audioContext.createMediaStreamDestination();

    const oscillator = audioContext.createOscillator();
    oscillator.frequency.value = 0; // Silence by setting frequency to 0
    oscillator.connect(destination);
    oscillator.start();
    
    emptyAudioMediaStream = destination.stream;
    emptyAudioMediaStream.getAudioTracks()[0].enabled = true; // Ensure it's silent
    
    return emptyAudioMediaStream;
  }
  
  const streamWidth = useNetworkUpSpeed(
    (livingRoom.share === ShareStreamOption.Stream || livingRoom.share === ShareStreamOption.File) &&
    inRoom.sharingInProgress, livingRoom.upSpeedUrl
  );
  useApplyVideoTrackConstrains(streamWidth, videoTrack);

  const captureMediaStream = useCallback(async () => {
    const videoEl = videoRef.current;
    if (!videoEl) {
      throw new Error('Cannot get media ref');
    }

    let capStream: MediaStream;
    if (videoEl.mozCaptureStream) {
      capStream = videoEl.mozCaptureStream();
      if (audioRef.current) {
        audioRef.current.srcObject = capStream;
      }
    } else if (videoEl.captureStream) {
      capStream = videoEl.captureStream();
    } else {
      throw new Error('Capture stream is not supported');
    }

    setVideoTrack(null);
    if (capStream.getVideoTracks().length > 0) {
      setVideoTrack(capStream.getVideoTracks()[0]);
    } else if (noVideoTrackRef.current) {
      capStream = new MediaStream([
        capStream.getAudioTracks()[0],
        noVideoTrackRef.current
      ]);
    }

    setCaptureStream(capStream);

    dispatch(setStreamDebugSentStream(capStream));
    return capStream;
  }, [dispatch]);

  const mergeAudioStreams = useCallback(
    (userStream: MediaStream, sharedStream: any, shareScreen: boolean = false) => {

      const audioContext = new AudioContext();
      // Create with shared stream
      const capturedAudioSource = audioContext.createMediaStreamSource(
        sharedStream
      );
      const audioDestination = audioContext.createMediaStreamDestination();

      // Add user microphone
      const userAudioSource = audioContext.createMediaStreamSource(userStream);
      userAudioSource.connect(audioDestination);

      if (sharedStream) {
        if (shareScreen) {
          capturedAudioSource.connect(audioDestination);
        } else {
          // Increase volume of shared stream
          const gainNode = audioContext.createGain();
          setGainNode(gainNode);
          capturedAudioSource.connect(gainNode);
          gainNode.connect(audioDestination);
          gainNode.gain.value = INIT_VOL;
        }
      }

      return { primaryStream:  getEmptyAudioStream(), secondaryStream: audioDestination.stream};

  }, []);

  const makeSecondaryCall = useCallback((stream: MediaStream, myName: string, sharing: string) => {
    // console.log("livingroom", livingRoom)
    // console.log("sharing",sharing)

    const getOutgoingBW = (sharing: string) => {
      switch (sharing) {
        case "stream_share":
          // console.log("inside track change", stream, myName)
          return 1300;
        case "screen_share":
          return 1200;
        default:
          return 1300;
      }
    }

    vertoSessionRef.current?.initSecondaryCall({
      stream,
      channelName: `${myName} sharing`,
      // @@@ receiveStream: sharing === "screen_share" ? false : true,
      receiveStream: true,
      incomingBandwidth: 1500,
      outgoingBandwidth: getOutgoingBW(sharing),
      destinationNumber: `${livingRoom.roomId}_stream_720`,
      connectionType: `merge_watch_stream_channel`,
    });
    if (userMediaRef.current) {
      // @@@ Is this the expected behaviour ? need to change GUI icon as well
      userMediaRef.current.getAudioTracks()[0].enabled = false;
    }
  }, [livingRoom])

  const handleSwitchChannel = useCallback((stream: SharedStream | null) => {
    const parseRoomMode = (mode: string) => {
      if(mode === "public") {
        return false
      } else if (mode === "private") {
        return true;
      }
      return false;
    }

    if (stream) {
      VlrService.patchMetadata({
        channelName: `${stream.name}`,
        streamId: stream.id,
        publicId: livingRoom.publicRoomId,
        logo: stream.logo,
        isPrivate: stream.is_adult_content ? true : parseRoomMode(livingRoom.mode)
      }).then();
    }
  }, [livingRoom])

  // @@@ Stream share callback
  const handleTrackChange = useCallback(async () => {
    try {
      console.log("HANDLE TRACK CHANGE CALLED");

      const capStream = await captureMediaStream();
      let mergedMedia: MediaStream | null = null;

      if (!livingRoom.singleConnection && userMediaRef.current) {
        const audio: any = mergeAudioStreams(userMediaRef.current, capStream);
        mergedMedia = new MediaStream([audio.secondaryStream.getAudioTracks()[0], capStream.getVideoTracks()[0]]);
      }

      console.log("share", livingRoom.share)

      console.log("sharedRef.current.newValue", sharedRef.current.newValue)

      const me = participantsRef.current.find(p => p.me);
      const stream = mergedMedia || capStream;

      if(me && livingRoom.share === ShareStreamOption.File && shareRef.current === ShareStreamOption.Stream && vertoSessionRef.current?.hasSecondaryCall())  {
        // vertoSessionRef.current.secondaryVertoCall?.hangup()
        console.log("when change from stream to file")
        vertoSessionRef.current?.replaceTracks(stream); // @@@ Primary or secondary ?
        // makeSecondaryCall(stream, me.participantName, "stream_share");
          // setIsSecondaryRecalled(true)
      }

      else if (me && (shareRef.current === ShareStreamOption.Camera || shareRef.current === ShareStreamOption.File) && !vertoSessionRef.current?.hasSecondaryCall()) {
        console.log("inside first secondary call shareref", shareRef.current)
        makeSecondaryCall(stream, me.participantName, "stream_share");
      } else {
        // const {secondaryVertoCall} = vertoSessionRef.current

        console.log("else part", "shareRef.current", shareRef.current)
        console.log("me", me)

        if(!isSecondaryRecalled && me && livingRoom.share === ShareStreamOption.Stream && vertoSessionRef.current?.hasSecondaryCall()) {
          console.log("inside secondary hangup shareRef", shareRef.current)
          vertoSessionRef.current.secondaryVertoCall?.hangup()
          makeSecondaryCall(stream, me.participantName, "stream_share");
          setIsSecondaryRecalled(true)
        } else {
         if (vertoSessionRef.current?.secondaryVertoCall) {
            console.log("inside not hangup shareRef", shareRef.current)
            const secondaryVertoCall = vertoSessionRef.current.secondaryVertoCall
            // @@@ This will not work (and parameters are wrong), but the parameter suppose to already be ok
            // secondaryVertoCall.dialogParams.outgoingBandwidth = 1300
            // secondaryVertoCall.rtc.options.receiveStream = false
          }
          // console.log("when switch to stream", vertoSessionRef.current, stream)
          vertoSessionRef.current?.replaceTracks(stream);
        }
      }

      dispatch(setInRoom({sharingInProgress: true}));
    } catch (e: any) {
      console.error(e);
      dispatch(setErrorToast('Unexpected error. Could not change the stream.'));
      vertoSessionRef.current?.hangup();
      vertoSessionRef.current?.cleanupWebRTC();
      vertoSessionRef.current = null;
    }
  }, [captureMediaStream, dispatch, livingRoom.singleConnection, mergeAudioStreams, makeSecondaryCall, livingRoom.share, isSecondaryRecalled]);

  const handleVjsPlayer = useCallback((videoEl?: HTMLVideoStreamElement) => {
    setVjsPlayer(videojs.getPlayer(MEDIA_VIDEO_ID) || null);
    videoRef.current = videoEl;
    videoEl && dispatch(setStreamDebugVideoElement(videoEl));
  }, [dispatch]);

  useEffect(() => {
    if (livingRoom.share === ShareStreamOption.Stream || livingRoom.share === ShareStreamOption.File) {
      setParticipantTalks(!micMuted || !!participants.find(p => !p.audio.muted && !p.isHostSharedVideo));
    }
  }, [micMuted, participants, livingRoom.share]);

  useEffect(() => {
    dispatch(resetStreamDebugValues());

    dispatch(setInRoom({
      isCoHost: false,
      loadingStream: false,
      sharingInProgress: shareRef.current !== ShareStreamOption.Camera
    }));

    document.onfullscreenchange = () => {
      setIsFullscreen(IS_IN_FULLSCREEN());
    };

    return () => {
      userMediaRef.current?.getTracks().forEach(t => t.stop());
      screenShareRef.current?.getTracks().forEach(t => t.stop());

      if (videojs.getPlayer(MEDIA_VIDEO_ID)) {
        videojs(MEDIA_VIDEO_ID).dispose();
      }

      if (IS_IN_FULLSCREEN()) {
        document.exitFullscreen().then();
      }

      vertoSessionRef.current?.hangup();
      vertoSessionRef.current?.cleanupWebRTC();
    };
  }, [dispatch, livingRoom.publicRoomId]);

  useEffect(() => {
    micMutedRef.current = micMuted;
  }, [micMuted]);

  useBeforeUnload(useCallback(() => {
    vertoSessionRef.current?.hangup();
    vertoSessionRef.current?.cleanupWebRTC();
  }, []));

  // @@@
  const getStreamLog = (stream: MediaStream) =>{
    if (!stream) {
      return 'NoStream';
    }
    const logLine = `MediaStream ID: ${stream.id}, Active: ${stream.active}, Audio Tracks: [${stream.getAudioTracks().map(t => `ID: ${t.id}, Label: ${t.label}, Enabled: ${t.enabled}, Muted: ${t.muted}, ReadyState: ${t.readyState}`).join(" | ")}], Video Tracks: [${stream.getVideoTracks().map(t => `ID: ${t.id}, Label: ${t.label}, Enabled: ${t.enabled}, Muted: ${t.muted}, ReadyState: ${t.readyState}`).join(" | ")}]`;
    return logLine;
  }

  const handleUserMediaChange = ({cam, mic}: WebRTCUserMedia) => {
    const audio = getMicParams(mic);
    const video = getCamParams(cam);

    console.log("HANDLE USER MEDIA CHANGE");

    navigator.mediaDevices
      .getUserMedia({audio, video})
      .then((userStream: MediaStream) => {
        if (!vertoSessionRef.current) {
          throw new Error('No session');
        }

        const sharedStream = captureStream || screenShareRef.current;

        console.log("sharedStream", sharedStream)

        if (!livingRoom.singleConnection && sharedStream) {
          const mergedAudioStream: any = mergeAudioStreams(userStream, sharedStream, !!screenShareRef.current);

          userStream.getAudioTracks()[0].enabled = userMediaRef.current?.getAudioTracks()[0].enabled || false;

          let video: MediaStreamTrack | null = null;
          if (userStream.getVideoTracks().length) {
            video = userStream.getVideoTracks()[0];
          } else if (noVideoTrackRef.current) {
            video = noVideoTrackRef.current;
          }

          const audio = mergedAudioStream.secondaryStream.getAudioTracks()[0];
          video && vertoSessionRef.current?.replacePrimaryVideoSecondaryAudioTrack(audio, video);
        } else {
          if (userStream.getVideoTracks().length) {
            vertoSessionRef.current.replacePrimaryTracks(userStream);
          } else if (noVideoTrackRef.current) {
            const audio = userStream.getAudioTracks()[0];
            const video = noVideoTrackRef.current;
            vertoSessionRef.current.replacePrimaryTracks(new MediaStream([audio, video]));
          }
        }

        userMediaRef.current?.getTracks().forEach(t => t.stop());
        userMediaRef.current = userStream;
      })
      .catch((err) => console.error(err));
  };

  const handleParticipantsChange = useCallback((participants: Participant[]) => {
    participantsRef.current = participants;

    setParticipants([...participants]);
  }, []);

  const stopScreenShareTracks = () => {
    if (screenShareRef.current) {
      screenShareRef.current.getTracks().forEach(track => track.stop());
      screenShareRef.current = null;
    }

    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  };

  const disconnectSecondaryCall = useCallback(() => {
    const changeHost = (me: Participant) => {
      VlrService.changeHost({participantId: me.participantId, roomId: livingRoom.roomId}).then();
      vertoSessionRef.current?.giveParticipantFloor(me.participantId);
    };

    const host = participantsRef.current.find(p => p.isHost);
    if (host) {
      changeHost(host);
    } else {
      const coHost = participantsRef.current.find(p => p.isCoHost);
      coHost && changeHost(coHost);
    }

    if (userMediaRef.current) {
      vertoSessionRef.current?.replaceSecondaryTracks(new MediaStream(userMediaRef.current.getAudioTracks()));
    }

    dispatch(setInRoom({sharingInProgress: false}));
    dispatch(setLivingRoom({share: ShareStreamOption.Camera}));
    dispatch(setStreamDebug({sentStream: userMediaRef.current || null, videoElement: null}));
  }, [livingRoom.roomId, dispatch]);

  const handleStopStream = () => {
    disconnectSecondaryCall();
    resetStream();
  };

  const handleStopScreenShare = useCallback(() => {
    if (livingRoom.singleConnection) {
      vertoSessionRef.current?.hangup();
      vertoSessionRef.current?.cleanupWebRTC();
    } else {
      disconnectSecondaryCall();
    }

    stopScreenShareTracks();
  }, [livingRoom.singleConnection, disconnectSecondaryCall]);

  const resetStream = useCallback(() => {
    videojs.getPlayer(MEDIA_VIDEO_ID) && videojs(MEDIA_VIDEO_ID).dispose();
    setVjsPlayer(null);
    setCaptureStream(null);
    dispatch(setLivingRoom({myStream: null, files: null, streamName: null}));
  }, [dispatch]);

  const handleStopSharing = useCallback(() => {
    disconnectSecondaryCall();
    stopScreenShareTracks();
    resetStream();
  }, [disconnectSecondaryCall, resetStream]);

  const handleStreamRefChange = useCallback((stream: MediaStream) => {
    screenShareRef.current?.getTracks().forEach(track => track.stop());
    screenShareRef.current = stream;
    screenShareRef.current.getVideoTracks()[0].onended = () => handleStopScreenShare;
    dispatch(setStreamDebug({sentStream: stream, videoElement: null}));
  }, [handleStopScreenShare, dispatch]);

  const handleOnUserStream = useCallback((userStream: MediaStream) => {
    userMediaRef.current = userStream;
  }, []);

  const handleToggleCam = async () => {
    setCamStopped(prevState => !prevState);
    userMediaRef.current?.getVideoTracks().forEach((track: MediaStreamTrack | CanvasCaptureMediaStreamTrack) => {
      if (!('canvas' in track)) {
        track.stop();
      }
    });
    const changeUserMedia = (videoTrack: MediaStreamTrack) => {
      const mediaStream = new MediaStream([videoTrack]);
      if (userMediaRef.current?.getTracks().length) {
        userMediaRef.current = new MediaStream([userMediaRef.current?.getAudioTracks()[0], videoTrack]);
      } else {
        userMediaRef.current = mediaStream;
      }
      livingRoom.share === ShareStreamOption.Camera && dispatch(setStreamDebugReplaceSentStream(mediaStream));
      vertoSessionRef.current?.replacePrimaryTracks(mediaStream);
    };

    if (camStopped) {
      try {
        const video = getCamParams(userMedia.cam, true);
        const stream = await navigator.mediaDevices.getUserMedia({audio: false, video});
        changeUserMedia(stream.getVideoTracks()[0]);
      } catch (e) {
        dispatch(setErrorToast('livingRoom.camError'));
        console.error(e);
        return;
      }
    } else if (noVideoTrackRef.current) {
      changeUserMedia(noVideoTrackRef.current);
    }

    vertoSessionRef.current?.togglePrimaryCam();
  };

  const handleToggleMic = () => {
    setMicMuted(prevState => !prevState);
    console.log(`@@@ handleToggleMic() new state:${micMuted}`);
    vertoSessionRef.current?.togglePrimaryMic();
  };

  const handleFullscreenChange = (value: boolean) => {
    if (value) {
      pageRef.current?.requestFullscreen().then();
    } else {
      document.exitFullscreen().then();
    }
  };

  const giveSharingFloor = () => {
    const sharing = participantsRef.current.find(p => p.me && p.isHostSharedVideo && !p.video.floor);
    sharing && vertoSessionRef.current?.giveParticipantFloor(sharing.participantId);
    vertoSessionRef.current?.sendMessage.stopAllMediaShare();
  };

  const handleScreenShare = async () => {
    const displayMediaStream = await getDisplayMedia();
    handleStreamRefChange(displayMediaStream);
    let mergedMediaStream: any = null;

    console.log("HANDLE SCREEN SHARE CALLED");
    debugger;
    if (livingRoom.joinCamMic && userMediaRef.current) {
      const mergedStreams = mergeAudioStreams(userMediaRef.current, displayMediaStream, true);
      mergedMediaStream = new MediaStream([
        mergedStreams.secondaryStream.getAudioTracks()[0],
        displayMediaStream.getVideoTracks()[0]
      ]);
    }

    const me = participants.find(p => p.me);
    if (me && !vertoSessionRef.current?.hasSecondaryCall() && mergedMediaStream) {
      makeSecondaryCall(mergedMediaStream, me.participantName, "screen_share");
      // @@@ Should replace primary
    } else {
      debugger;
      if(vertoSessionRef.current?.secondaryVertoCall) {
        const secondaryVertoCall = vertoSessionRef.current.secondaryVertoCall
        secondaryVertoCall.dialogParams.outgoingBandwidth = 1200
        // @@@ Does this works ?
        secondaryVertoCall.rtc.options.receiveStream = false
      }
      
      // console.log("secondary verto call screen", vertoSessionRef.current)
      vertoSessionRef.current?.replaceTracks(mergedMediaStream || displayMediaStream);
      giveSharingFloor();
    }

    me && vertoSessionRef.current?.sendMessage.stopMediaShare(me.callId);
    dispatch(setLivingRoom({share: ShareStreamOption.Screen}));
    dispatch(setInRoom({sharingInProgress: true}));
  };

  const handleShareMyCamera = () => {
    handleUserMediaChange(userMedia);
  };

  const handleVertoSessionChange = useCallback((vertoSession: VertoSession) => {
    vertoSession.notification.onSecondaryCallRemoteStream.subscribe(() => {
      if (userMediaRef.current) {
        userMediaRef.current.getAudioTracks()[0].enabled = !micMutedRef.current;
        console.log(`@@@ handleVertoSessionChange() userMediaRef.current. enabled:${userMediaRef.current.getAudioTracks()[0].enabled} trackId:${userMediaRef.current.getAudioTracks()[0].id} `)
      }
    });

    vertoSession.notification.onReplaceTracksDone.subscribe(() => {
      if (sharedRef.current.oldValue === sharedRef.current.newValue) {
        return;
      }

      sharedRef.current.oldValue = sharedRef.current.newValue;

      const stopUserTracks = () => {
        livingRoom.singleConnection && userMediaRef.current?.getTracks().forEach(t => t.stop());
      };

      switch (sharedRef.current.newValue) {
        case ShareStreamOption.Camera:
          stopScreenShareTracks();
          resetStream();
          dispatch(setLivingRoom({share: sharedRef.current.newValue}));
          break;
        case ShareStreamOption.Stream:
        case ShareStreamOption.File:
          stopUserTracks();
          stopScreenShareTracks();
          break;
        case ShareStreamOption.Screen:
          stopUserTracks();
          resetStream();
          dispatch(setLivingRoom({share: sharedRef.current.newValue}));
          break;
      }
    });

    vertoSession.notification.onStopAllMediaShare.subscribe(() => {
      disconnectSecondaryCall();
      stopScreenShareTracks();
      resetStream();
    });
    vertoSessionRef.current = vertoSession;
  }, [dispatch, disconnectSecondaryCall, livingRoom.singleConnection, resetStream]);

  const handleOnLeave = () => {
    vertoSessionRef.current?.hangup();
    vertoSessionRef.current?.cleanupWebRTC();
    vertoSessionRef.current = null;
  };

  const handleMicMuted = useCallback((muted: boolean) => {
    setMicMuted(muted);
    if (userMediaRef.current) {
      const audioTrack = userMediaRef.current.getAudioTracks()[0];
      userMediaRef.current.getAudioTracks()[0].enabled = !muted;
      console.log(`@@@ handleMicMuted() userMediaRef.current. enabled:${audioTrack.enabled} trackId:${audioTrack.id} `)
    }
  }, []);

  const handleOnWatchPartySessionLeave = useCallback(() => {
    history.replace(livingRoom.joinedFromJoinScreen ? Routes.Home : Routes.WatchPartyStart2);
  }, [history, livingRoom.joinedFromJoinScreen]);

  const handleExitAlert = () => {
    setIsExitAlert(true)
  }

  return (
    <Layout>
      <ProgressLoader
        progress={progress}
        show={loading}
        showLeave={showProgressLeave}
        onLeave={() => history.replace(Routes.WatchPartyStart2)}
      />

      <main
        ref={pageRef}
        hidden={loading}
        className={
          `living-room-main${isFullscreen ?
            (livingRoom.share === ShareStreamOption.Stream || livingRoom.share === ShareStreamOption.File ? '-fullscreen player-controller' : '-fullscreen')
            : ''}`
        }
      >
        <section className={`living-room-chat-container ${showChat ? 'side-content-open' : ''}`}>
          <AdSenseCard
            slot={AdSenseSlot.Left}
            format={AdSenseFormat.Rectangle}
            className="ad"
          />

          {!loading && vertoSessionRef.current && (
            <Chat
              vlrId={livingRoom.vlrId}
              session={vertoSessionRef.current}
              participants={participants}
              show={showChat}
            />
          )}
        </section>

        <section className="video-room-container">
          <TopBar onLeave={handleExitAlert}/>
          <main className="video-room-main">
            <div className="video-room-inner">
              <NoVideoCanvas onVideoTrack={handleNoVideoTrack}/>
              <WatchPartySession
                noVideoTrack={noVideoTrackRef}
                captureMediaStream={captureMediaStream}
                onMicMuted={handleMicMuted}
                onCamStopped={setCamStopped}
                onShowProgressLeave={setShowProgressLeave}
                onVertoSession={handleVertoSessionChange}
                onProgress={setProgress}
                onShowLoading={setLoading}
                onParticipants={handleParticipantsChange}
                onLeave={handleOnWatchPartySessionLeave}
                onGainNode={setGainNode}
                onUserStream={handleOnUserStream}
                onStopSharing={handleStopSharing}
                onMergeStreams={mergeAudioStreams}
                onScreenShareStream={handleStreamRefChange}
                onVjsPlayer={handleVjsPlayer}
              />
              {
                vjsPlayer &&
                (livingRoom.share === ShareStreamOption.Stream || livingRoom.share === ShareStreamOption.File) &&
                !loading &&
                <PlayerControlBar
                  vjs={vjsPlayer}
                  talking={participantTalks}
                  files={livingRoom.files}
                  myStream={livingRoom.myStream}
                  onTrackChange={handleTrackChange}
                  gainNode={gainNode}
                />
              }
            </div>

            {!loading && (
              <SideBar
                showParticipants={showParticipants}
                showChat={showChat}
                showDebugStream={showDebugStream}
                micMuted={micMuted}
                camStopped={camStopped}
                isFullscreen={isFullscreen}
                onShowParticipants={setShowParticipants}
                onShowChat={setShowChat}
                onShowDebugStream={setShowDebugStream}
                onUserMediaChange={handleUserMediaChange}
                onToggleCam={handleToggleCam}
                onToggleMic={handleToggleMic}
                onFullscreen={handleFullscreenChange}
                onChangeStream={() => setShowChangeStream(true)}
                onChangeFile={() => setShowChangeFile(true)}
                onStopStream={handleStopStream}
                onLayoutChange={layout => vertoSessionRef.current?.changeLayout(layout)}
                onScreenShare={handleScreenShare}
                onStopScreenShare={handleStopScreenShare}
                onShareMyCamera={handleShareMyCamera}
                onSharedOptionChanged={option => sharedRef.current.newValue = option}
                setIsSecondaryRecalled={setIsSecondaryRecalled}
              />
            )}
          </main>
        </section>

        <audio ref={audioRef} autoPlay hidden/>

        <section
          className={`living-room-side-features ${showDebugStream || showParticipants ? 'side-content-open' : ''}`}>
          <AdSenseCard
            slot={AdSenseSlot.Right}
            format={AdSenseFormat.Rectangle}
            className="ad"
          />

          {showDebugStream && <StreamDebugInfo/>}

          {
            vertoSessionRef.current &&
            <Participants
              session={vertoSessionRef.current}
              participants={participants}
              host
              show={showParticipants}
            />
          }
        </section>

        <FullscreenListeners isInFullscreen={isFullscreen}/>
      </main>

      <ChangeStream
        show={showChangeStream}
        onOk={giveSharingFloor}
        onClose={() => setShowChangeStream(false)}
        handleSwitchChannel={handleSwitchChannel}
        onStopStream={handleStopStream}
      />

      <ChangeFile
        show={showChangeFile}
        onOk={giveSharingFloor}
        onClose={() => setShowChangeFile(false)}
      />

      {isExitAlert && <IonAlert
        isOpen={isExitAlert}
        onDidDismiss={() => setIsExitAlert(false)}
        message={t("watchPartyStart.aboutToLeave")}
        buttons={[
          {
            text: `${t("common.decline")}`,
            role: 'cancel'
          },
          {
            text: `${t("common.leave")}`,
            handler: () => {
              handleOnLeave();
            }
          }
        ]}
      />}
    </Layout>
  );
};

export default LivingRoom;
