import React from 'react';
import './styles.scss';
import {IonButton, IonButtons, IonFooter, IonImg, IonList, IonText, IonToolbar} from '@ionic/react';
import footerLogo from '../../images/12all-footer.png';
import {useTranslation} from 'react-i18next';
import {Routes} from '../../shared/routes';

const Footer: React.FC = () => {
  const {t} = useTranslation();

  // const {uplinkSpeed,streamWidth} = useSelector(({networkData}: ReduxSelectors) => networkData);
  return (
    <>
      <IonFooter className="app-footer">
        <IonToolbar>
          <IonList>
            <IonImg src={footerLogo}/>
            {/*{uplinkSpeed > 0 && streamWidth > 0 ? */}
            {/*<div style={{display:'flex', flexDirection:'row'}}>*/}
            {/*  <div>Uplink Speed - <span style={{color:'red'}}>{uplinkSpeed.toFixed(2)} Mbps </span></div>*/}
            {/*  <div style={{marginLeft:20}}>Stream Width - <span style={{color:'red'}}>{streamWidth} </span></div>*/}
            {/*</div>: null}*/}
            <IonButtons>
              <IonButton color="medium" routerLink={Routes.PrivacyPolicy}
                         routerDirection="back">{t('footer.privacy')}</IonButton>
              <IonButton color="medium" routerLink={Routes.TermsAndConditions}
                         routerDirection="back">{t('footer.terms')}</IonButton>
            </IonButtons>
          </IonList>
        </IonToolbar>
        <IonText color="medium" className="copyright">{t('footer.copyright')}</IonText>
      </IonFooter>
    </>
  );
};

export default Footer;
