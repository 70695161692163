import React, {FC, FormEvent, useState} from 'react';
import './styles.scss';
import Layout from '../../components/Layout';
import {useTranslation} from 'react-i18next';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle, IonIcon,
  IonInput,
  IonItem,
  IonLabel, IonLoading, IonTitle
} from '@ionic/react';
import {RouteComponentProps} from 'react-router';
import {useDispatch} from 'react-redux';
import {Routes} from '../../shared/routes';
import {setErrorToast, setInfoToast} from '../../redux/actions/toastActions';
import {AuthService, UserManagementService} from '../../services';
import crossIcon from "../../images/icons/cross.svg";
import {key, personCircleOutline} from "ionicons/icons";
import GoogleRecaptchaV3 from "../../components/RecaptchaV3";
import SelectCountryCode from "../Login_v2/SelectInputCountry";

const BACKGROUND_COLOR = 'secondary-new';

const ResetCodePage: FC<RouteComponentProps> = ({history, location}: RouteComponentProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    console.log(countryCode, inputData, recaptchaToken);
    if (!inputData.identifier || !countryCode) {
      dispatch(setErrorToast("Please input the phone number"));
      return;
    }
    if (!isRecaptchaVerified || !recaptchaToken) {
      dispatch(setErrorToast("Please check the recaptcha"));
      return;
    }
    setSubmitting(true);

    let status = await UserManagementService.sendConfirmationCode(countryCode + inputData.identifier, recaptchaToken);
    if (status.data.status === "ok") {
      setCountryCode("");
      setInputData({ ...inputData, identifier: "" });
      history.replace(Routes.changePassword);
    }
    else{
      dispatch(setErrorToast("phoneNumberValidation.invalidNumber"));
    }

    setSubmitting(false);
  };

  const [recaptchaToken, setRecaptchaToken] = useState<string>("");
  const [isRecaptchaVerified, setIsRecaptchaVerified] =
      useState<boolean>(false);

  const [countryCode, setCountryCode] = useState("");
  const [inputData, setInputData] = useState({
    identifier: "",
  });

  const onChange = (e: any) => {
    if (e.target.name === "phoneNumber") {
      setInputData({ ...inputData, identifier: e.target.value });
    } else {
      setInputData({ ...inputData, [e.target.name]: e.target.value });
    }
  };

  return (
    <Layout className="center">
      <IonCard color={BACKGROUND_COLOR} className="change-password-container">

        <IonButton
            color="transparent"
            className="ion-transparent-button"
            onClick={() => {
              history.goBack();
            }}
        >
          <IonIcon slot="icon-only" color="white" icon={crossIcon} />
        </IonButton>

        <IonCardHeader>
          <IonCardTitle>{t('resetPassword.title')}</IonCardTitle>
        </IonCardHeader>

        <IonCardContent>

          <div className={"icon-block"}>
            <IonIcon icon={key} color="dark"/>

            <span className={"mt-4 text-[#ff0000]"}>{(!isRecaptchaVerified) ? "Recaptcha Code Expired" : ""}</span>
          </div>

          <form noValidate onSubmit={handleSubmit}>

            <div className="flex flex-col gap-1.5">
              <p className="text-sm">Phone number</p>
              <div className="flex items-center justify-center h-[50px] gap-2">
                <SelectCountryCode
                    onSelect={(value) => setCountryCode(value.countryCode)}
                    inputPlaceholder={t("login.countryCode")}
                    disabled={false}
                    className="h-12 pt-1 bg-white border text-[#a5a5a5] w-20 rounded"
                />
                <input
                    className="flex-1 h-12 bg-white border text-[#a5a5a5] rounded pl-2"
                    name="phoneNumber"
                    onChange={onChange}
                    value={inputData.identifier}
                />
              </div>
            </div>

            <div className="flex justify-start my-6">
              <GoogleRecaptchaV3
                  setIsRecaptchaVerified={setIsRecaptchaVerified}
                  setRecaptchaToken={setRecaptchaToken}
              />
            </div>

            <IonLoading isOpen={submitting} />

            <IonButton
                type="submit"
                disabled={!countryCode || !inputData.identifier || !recaptchaToken  || submitting}
            >
              {t('common.submit')}
            </IonButton>
            <IonButton type="button" color="dark" onClick={() => history.replace(Routes.Home)}>
              {t('common.cancel')}
            </IonButton>
          </form>
        </IonCardContent>
      </IonCard>
    </Layout>
  );
};

export default ResetCodePage;
