export enum ToastTextFormat {
  i18,
  Text
}

export enum SearchType {
  All= 'all',
  Channel = 'channel',
  Room = 'room',
  User = 'user',
  Followers = 'followers',
  Following = 'following'
}
