export const SET_LIVING_ROOM = 'SET_LIVING_ROOM';
export const SET_JOIN_LIVING_ROOM = 'SET_JOIN_LIVING_ROOM';
export const SET_SHARED_STREAM_LIVING_ROOM = 'SET_SHARED_STREAM_LIVING_ROOM';
export const SET_ROOM_TEST = 'SET_ROOM_TEST';

export const SET_UNREAD_MESSAGES = 'SET_UNREAD_MESSAGES';
export const SET_ACCUMULATOR_TO_INITIAL_UNREAD_MESSAGES = 'SET_ACCUMULATOR_TO_INITIAL_UNREAD_MESSAGES';
export const SET_RESET_UNREAD_MESSAGES = 'SET_RESET_UNREAD_MESSAGES';

export const SET_USER_MEDIA = 'SET_USER_MEDIA';
export const SET_SUCCESS_TOAST = 'SET_SUCCESS_TOAST';
export const SET_ERROR_TOAST = 'SET_ERROR_TOAST';
export const SET_RESET_TOAST = 'SET_RESET_TOAST';
export const SET_WARN_TOAST = 'SET_WARN_TOAST';
export const SET_INFO_TOAST = 'SET_INFO_TOAST';

export const SET_WP_TEMPLATES = 'SET_WP_TEMPLATES';
export const SET_WP_CURRENT_TEMPLATES = 'SET_WP_CURRENT_TEMPLATES';
export const RESET_WP_CURRENT_TEMPLATES = 'RESEt_WP_CURRENT_TEMPLATES';
export const SET_VLR_TEMPLATES = 'SET_VLR_TEMPLATES';
export const SET_SELECTED_VLR_TEMPLATE = 'SET_SELECTED_VLR_TEMPLATE';
export const PATCH_SELECTED_VLR_TEMPLATE = 'PATCH_SELECTED_VLR_TEMPLATE';
export const PATCH_SELECTED_VLR_TEMPLATE_SCHEDULE = 'PATCH_SELECTED_VLR_TEMPLATE_SCHEDULE';
export const ADD_VLR_TEMPLATE = 'ADD_VLR_TEMPLATE';
export const RESET_SELECTED_VLR_TEMPLATE = 'RESET_SELECTED_VLR_TEMPLATE';

export const SET_LOGIN = 'SET_LOGIN';
export const SET_LOGOUT = 'SET_LOGOUT';

export const SET_CHANNELS_FILTER_OPTION = 'SET_CHANNELS_FILTER_OPTION';
export const RESET_CHANNELS_FILTER_OPTION = 'RESET_CHANNELS_FILTER_OPTION';

export const SET_CHANNELS = 'SET_CHANNELS';
export const SET_FAVORITE_LIVE_ROOMS = 'SET_FAVORITE_LIVE_ROOMS';
export const ON_CHANNEL_PREVIEW_ERROR = 'ON_CHANNEL_IMG_ERROR';
export const ON_CHANNEL_PREVIEW_LOADED = 'ON_CHANNEL_PREVIEW_LOADED';
export const TOGGLE_CHANNEL_FAVORITE = 'TOGGLE_CHANNEL_FAVORITE';

export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_SIGN_UP_DATA = 'SET_SIGN_UP_DATA';

export const SET_STREAMS = 'SET_STREAMS';
export const SET_STREAMS_ROW = 'SET_STREAMS_ROW';
export const LOADING_STREAMS = 'LOADING_STREAMS';
export const SET_FAVORITE_STREAMS = 'SET_FAVORITE_STREAMS';
export const ADD_FAVORITE_STREAM = 'ADD_FAVORITE_STREAM';
export const REMOVE_FAVORITE_STREAM = 'REMOVE_FAVORITE_STREAM';
export const SET_STREAMS_SNAPSHOT = 'SET_STREAMS_SNAPSHOT';
export const TOGGLE_STREAM_FAVORITE = 'TOGGLE_STREAM_FAVORITE';
export const SET_SHARED_SITE_DATA = 'SET_SHARED_SITE_DATA';
export const ALLOW_ADS = 'ALLOW_ADS';
export const SET_WEB_CONFIG = 'SET_WEB_CONFIG';

export const STREAM_LOADING_START = 'STREAM_LOADING_START';
export const STREAM_LOADING_DONE = 'STREAM_LOADING_DONE';

export const SET_IN_ROOM = 'SET_IN_ROOM';
export const SET_ROOM_LAYOUT = 'SET_ROOM_LAYOUT';

export const SET_NETWORK_DATA = 'SET_NETWORK_DATA';
export const SET_NETWORK_CONFIG = 'SET_NETWORK_CONFIG';

export const STREAM_DEBUG_SET_SENT_STREAM = 'STREAM_DEBUG_SET_SENT_STREAM';
export const STREAM_DEBUG_SET_REPLACE_SENT_STREAM = 'STREAM_DEBUG_SET_REPLACE_SENT_STREAM';
export const STREAM_DEBUG_SET_RECEIVED_STREAM = 'STREAM_DEBUG_SET_RECEIVED_STREAM';
export const STREAM_DEBUG_SET_VIDEO_ELEMENT = 'STREAM_DEBUG_SET_VIDEO_ELEMENT';
export const STREAM_DEBUG_SET_HLS_ERROR = 'STREAM_DEBUG_SET_HLS_ERROR';
export const STREAM_DEBUG_RESET_VALUES = 'STREAM_DEBUG_RESET_VALUES';
export const STREAM_DEBUG_VERTO_SESSION = 'STREAM_DEBUG_VERTO_SESSION';
export const SET_STREAM_DEBUG = 'SET_STREAM_DEBUG';

export const CHANGE_HEADER_SEARCH = 'CHANGE_HEADER_SEARCH';
export const SET_HOME_FILTER = 'SET_HOME_FILTER';

export const SET_SEARCH = 'SET_SEARCH';
export const SET_CURRENT_STREAM_ROUTE = 'SET_CURRENT_STREAM_ROUTE';

// Billing
export const SET_ENABLE_POPUP = 'SET_ENABLE_POPUP';
export const SET_BILLING_REWARD = 'SET_BILLING_REWARD';
export const SET_BILLING_AVATAR_REWARD = 'SET_BILLING_AVATAR_REWARD';
export const SET_FIRST_FAVORITE_AWARD = 'SET_FIRST_FAVORITE_AWARD';
export const SET_TOTAL_STAR_BALANCE = 'SET_TOTAL_STAR_BALANCE';
export const SET_OPEN_CHANNEL_DIRECT_STREAM = 'SET_OPEN_CHANNEL_DIRECT_STREAM';
export const SET_BILLING_TIME_EVENTS = 'SET_BILLING_TIME_EVENTS';
export const SET_SUBSCRIPTION_TYPE = 'SET_SUBSCRIPTION_TYPE';
