import React, {FC, useEffect, useState} from 'react';
import './styles.scss';
import {IonIcon, IonItem, IonSpinner, IonText, IonToolbar} from '@ionic/react';
import {enter, film, people} from 'ionicons/icons';
import {Participant} from '../../../verto/models';
import {useTranslation} from 'react-i18next';
import ExitButton from '../../../components/ExitButton';
import {streamLoadingDone} from '../../../redux/actions/streamLoadingActions';
import {useDispatch, useSelector} from 'react-redux';
import {ReduxSelectors} from '../../../redux/shared/types';
import {EpgEntry} from '../../../shared/types';
import NowPlaying from '../../../components/NowPlaying';

type Props = {
  streamName?: string;
  roomId?: string;
  epgEntries: EpgEntry[];
  participants: Participant[];
  onExit: () => void;
};

const TopBarStream: FC<Props> = ({streamName, roomId, epgEntries, participants, onExit}: Props) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {loading} = useSelector(({streamLoading}: ReduxSelectors) => streamLoading);
  const [numberOfParticipants, setNumberOfParticipants] = useState<number>(1);

  useEffect(() => {
    return () => {
      dispatch(streamLoadingDone());
    };
  }, [dispatch]);

  useEffect(() => {
    setNumberOfParticipants(participants.filter(p => !p.isHostSharedVideo).length || 1);
  }, [participants]);

  return (
    <IonToolbar className="top-bar-stream-share">
      <ExitButton onExit={onExit}/>
      <IonItem className="room-info" slot="end" lines="none">
        {
          streamName &&
          <div className="room-info-row" title={t('sharedStream.streamName')}>
            <IonIcon icon={film}/>
            <IonText>{streamName}</IonText>
            {loading && <IonSpinner/>}
          </div>
        }
        {epgEntries.length > 0 && <NowPlaying epgEntries={epgEntries} streamName={streamName}/>}
        <div className="room-info-row" title={t('sharedStream.roomNumber')}>
          <IonIcon icon={enter}/>
          <IonText>{roomId}</IonText>
        </div>
        <div className="room-info-row" title={t('sharedStream.numberOfParticipants')}>
          <IonIcon icon={people}/>
          <IonText>{numberOfParticipants}</IonText>
        </div>
      </IonItem>
    </IonToolbar>
  );
};

export default TopBarStream;
