export enum ChatMethod {
  AskToUnmuteMic = 'ask_to_unmute_mic',
  AskToStartCam = 'ask_to_start_cam',
  ToEveryone = 'to_everyone',
  OneToOne = 'one_to_one',
  SwitchHostStream = 'switch_host_stream',
  SwitchHostCamera = 'switch_host_camera',
  ChangeParticipantState = 'change_participant_state',
  StreamChange = 'stream_change',
  MakeCoHost = 'make_co_host',
  RemoveCoHost = 'remove_co_host',
  RemoveParticipant = 'remove_participant',
  HostLeft = 'host_left',
  StopMediaShare = 'stop_media_share',
  StopAllMediaShare = 'stop_all_media_share',
  BlockParticipant = 'block_participant',
  YouAreHost = 'you_are_host',
}
