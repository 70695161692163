export enum ShareStreamOption {
  Stream = 'stream',
  File = 'file',
  Camera = 'camera',
  Screen = 'screen',
  Hls = 'hls'
}

export enum LivingRoomMode {
  Public = 'public',
  Private = 'private',
  Paid = 'paid'
}
