import React, {FC, FormEvent, useState} from 'react';
import './styles.scss';
import Layout from '../../components/Layout';
import {useTranslation} from 'react-i18next';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle, IonIcon,
  IonInput,
  IonItem,
  IonLabel, IonTitle
} from '@ionic/react';
import {RouteComponentProps} from 'react-router';
import {useDispatch} from 'react-redux';
import {Routes} from '../../shared/routes';
import {setErrorToast, setInfoToast} from '../../redux/actions/toastActions';
import {AuthService, UserManagementService} from '../../services';
import crossIcon from "../../images/icons/cross.svg";
import {key, personCircleOutline} from "ionicons/icons";
import GoogleRecaptchaV3 from "../../components/RecaptchaV3";

const BACKGROUND_COLOR = 'secondary-new';

const ChangePasswordPage: FC<RouteComponentProps> = ({history, location}: RouteComponentProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [otpCode, setOtpCode] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!password || !passwordConfirmation || password !== passwordConfirmation) {
      dispatch(setErrorToast('resetPassword.invalidPasswords'));
      return;
    }

    setSubmitting(true);

    UserManagementService.changePassword(otpCode!, password)
      .then((response) => {
        if(response.data?.status == "ok"){
          dispatch(setInfoToast('resetPassword.passwordReset'));
          setTimeout(function () {
            setOtpCode("");
            setPassword("");
            setPasswordConfirmation("");
            history.push(Routes.Home);
          }, 1000);
        }
        else if (response.data?.status == "nok" && response.data.error.code == 400) {
          dispatch(setErrorToast(response.data.error.message));
        }
        else {
          dispatch(setErrorToast(`resetPassword.error`));
        }
      })
      .catch((error: any) => {
        dispatch(setErrorToast(`resetPassword.error`));
      }).finally(() => {
        setSubmitting(false);
      });
  };

  const [isRecaptchaVerified, setIsRecaptchaVerified] =
      useState<boolean>(false);

  return (
    <Layout className="center">
      <IonCard color={BACKGROUND_COLOR} className="change-password-container">

        <IonButton
            color="transparent"
            className="ion-transparent-button"
            onClick={() => {
              history.replace(Routes.resetCode);
            }}
        >
          <IonIcon slot="icon-only" color="white" icon={crossIcon} />
        </IonButton>

        <IonCardHeader>
          <IonCardTitle>{t('resetPassword.title')}</IonCardTitle>
        </IonCardHeader>

        <IonCardContent>

          <div className={"icon-block"}>
            <IonIcon icon={key} color="dark"/>

            <span className={"mt-4 text-[#ff0000]"}>{(password !== passwordConfirmation) ? "Password does not match" : ""}</span>
          </div>

          <form noValidate onSubmit={handleSubmit}>

            <IonItem color={BACKGROUND_COLOR} className={"mb-12"}>
              <IonLabel position="stacked">
                {t('resetPassword.otpCode')}
              </IonLabel>
              <IonInput
                  type="tel"
                  name="otpCode"
                  maxlength={4}
                  placeholder={t('resetPassword.otpCodePlaceholder')}
                  required
                  value={otpCode}
                  onIonChange={({detail: {value}}) =>
                      setOtpCode(value ? value.trim() : '')
                  }
              />
            </IonItem><br/><br/>

            <IonItem color={BACKGROUND_COLOR}>
              <IonLabel position="stacked">
                {t('resetPassword.newPassword')}
              </IonLabel>
              <IonInput
                  type="password"
                  name="password"
                  placeholder={t('resetPassword.newPasswordPlaceholder')}
                  required
                  value={password}
                  onIonChange={({detail: {value}}) =>
                      setPassword(value ? value.trim() : '')
                  }
              />
            </IonItem>

            <IonItem color={BACKGROUND_COLOR}>
              <IonLabel position="stacked">
                {t('resetPassword.confirmPassword')}
              </IonLabel>
              <IonInput
                  type="password"
                  name="passwordConfirmation"
                  placeholder={t('resetPassword.confirmPasswordPlaceholder')}
                  required
                  value={passwordConfirmation}
                  onIonChange={({detail: {value}}) =>
                      setPasswordConfirmation(value ? value.trim() : '')
                  }
              />
            </IonItem>

            <div className={"flex justify-center"}>
              <IonButton
                  className="w-100"
                  type="submit"
                  disabled={!password || !passwordConfirmation || password !== passwordConfirmation || submitting}
              >
                {t('common.submit')}
              </IonButton>
            </div>
          </form>
        </IonCardContent>
      </IonCard>
    </Layout>
  );
};

export default ChangePasswordPage;
