import React, {FC, useEffect, useRef, useState} from 'react';
import './styles.scss';
import {
  IonAvatar,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonList,
  IonListHeader,
  IonPopover,
  IonText
} from '@ionic/react';
import VertoSession from '../../verto/VertoSession';
import {IncomingMessage, Participant, ParticipantParams} from '../../verto/models';
import {paperPlaneOutline, people} from 'ionicons/icons';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {
  setAccumulatorToInitialUnreadMessages,
  setResetUnreadMessages,
  setUnreadMessages
} from '../../redux/actions/unreadMessagesActions';
import {ReduxSelectors} from '../../redux/shared/types';
import {ChatHistoryService} from '../../services';

type Props = {
  vlrId: number;
  session: VertoSession;
  participants: Participant[];
  show: boolean;
};

type CurrentChat = { callId: string; placeholder?: string; newMessageId?: string; avatar?: { text: string; color: string }, left: boolean };
type ChatObj = { [id: string]: { chat: IncomingMessage[], disabled: boolean } };

const EVERYONE = 'everyone';
const CHAT_PARTICIPANTS_POPOVER_ID = 'chat-participants-popover-id';

const DEFAULT_SELECTED_STATE = {callId: EVERYONE, left: false};

const Chat: FC<Props> = (({vlrId, session, participants, show}: Props) => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const didMountRef = useRef<boolean>(false);
  const popRef = useRef<HTMLIonPopoverElement>(null);
  const messagesRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLIonInputElement>(null);

  const unreadMessages = useSelector(({unreadMessages}: ReduxSelectors) => unreadMessages);

  // const participantsRef = useRef<HTMLIonRadioGroupElement>(null);

  const [chatObj, setChatObj] = useState<ChatObj>({
    everyone: {
      chat: [],
      disabled: false
    }
  });
  const [current, setCurrent] = useState<CurrentChat>(DEFAULT_SELECTED_STATE);
  const [chatParticipants, setChatParticipants] = useState<{ unread: number; accumulator: number; participants: Participant[] }>({
    unread: 0,
    accumulator: 0,
    participants: []
  });
  const [filteredParticipants, setFilteredParticipants] = useState<Participant[]>(participants);

  useEffect(() => {
    ChatHistoryService.getMessages(vlrId)
      .then(({data}) => {
        const chat = data.map(m => new IncomingMessage(EVERYONE, m.sender, m.message, false, new Date(m.date)));

        setChatObj(prevState => (
          {
            ...prevState,
            everyone: {
              ...prevState.everyone,
              chat: [...chat, ...prevState.everyone.chat]
            }
          }
        ));
      });

    return () => {
      dispatch(setResetUnreadMessages());
    };
  }, [dispatch, vlrId]);

  useEffect(() => {
    setFilteredParticipants(participants.filter(p => p.isPrimaryCall !== undefined ? p.isPrimaryCall : !p.isHostSharedVideo));
  }, [participants]);

  useEffect(() => {
    setChatObj(prevState => (
      filteredParticipants
        .reduce((acc: ChatObj, participant: Participant) => {
          if (!prevState[participant.callId]) {
            acc[participant.callId] = {disabled: false, chat: []};
          }
          return acc;
        }, prevState)
    ));

    setChatParticipants((prev) => {
      const mappedParticipants = filteredParticipants.map(participant => {
        const existingParticipant = prev.participants.find(prevParticipant => prevParticipant.callId === participant.callId);
        if (existingParticipant) {
          participant.unread = existingParticipant.unread;
        }
        return participant;
      });

      const params: ParticipantParams = {
        callId: EVERYONE,
        participantId: EVERYONE,
        participantName: EVERYONE,
        audio: {
          muted: true,
          talking: false
        },
        video: {
          muted: true,
          floor: false,
          floorLocked: false
        }
      };

      const everyoneParticipant = new Participant(params);

      if (prev.participants.length) {
        everyoneParticipant.unread = prev.participants[0].unread;
      }

      mappedParticipants.unshift(everyoneParticipant);

      return {
        ...prev,
        participants: mappedParticipants
      };
    });
  }, [filteredParticipants]);

  useEffect(() => {
    if (current.callId === EVERYONE) {
      return;
    }

    const participantIsInTheRoom = filteredParticipants.find(p => p.callId === current.callId);
    if (!participantIsInTheRoom) {
      setCurrent(prevState => {
        if (!prevState.left && inputRef.current) {
          inputRef.current.value = '';
          return {...prevState, left: true};
        }

        return prevState;
      });
    }
  }, [filteredParticipants, current.callId]);

  useEffect(() => {
    const notification = session.notification;

    const allId = notification.onChatMessageToAll.subscribe((im: IncomingMessage) => {
      setChatObj(prev => (
        {
          ...prev,
          everyone: {
            ...prev.everyone,
            chat: [...prev.everyone.chat, im]
          }
        }
      ));

      setCurrent(prev => ({...prev, newMessageId: EVERYONE}));
    });

    const one2oneId = notification.onChatMessageOneToOne.subscribe((im: IncomingMessage) => {
      setChatObj(prev => (
        {
          ...prev,
          [im.toId]: {
            ...prev[im.toId],
            chat: [...prev[im.toId].chat, im]
          }
        }
      ));

      setCurrent(prev => ({...prev, newMessageId: im.toId}));
    });

    return () => {
      notification.onChatMessageToAll.unsubscribe(allId);
      notification.onChatMessageToAll.unsubscribe(one2oneId);
    };
  }, [session.notification]);

  useEffect(() => {
    setChatParticipants(prev => {
      const newState = prev.participants.map((p) => {
        if (p.callId === current.newMessageId) {
          p.unread = p.unread + 1;
          if (p.callId === current.callId) {
            p.unread = 0;
          }
        }

        return p;
      });

      const unread = newState.reduce((accumulator: number, {unread}: Participant) => {
        if (unread > 0) {
          accumulator = accumulator + unread;
        }

        return accumulator;
      }, 0);

      return {
        unread,
        participants: newState,
        accumulator: prev.accumulator + 1
      };
    });

    // Scroll when the last el is rendered
    setTimeout(() => messagesRef.current?.scrollIntoView(false));
  }, [current, dispatch]);

  useEffect(() => {
    if (didMountRef.current) {
      dispatch(setUnreadMessages(chatParticipants.unread));
    } else {
      didMountRef.current = true;
    }

  }, [chatParticipants.accumulator, chatParticipants.unread, dispatch]);

  useEffect(() => {
    if (!show) {
      dispatch(setAccumulatorToInitialUnreadMessages());
    }
  }, [show, dispatch]);

  const handleSend = () => {
    if (!inputRef.current) return;

    const message = inputRef.current.value as string;
    if (message.trim().length) {
      if (current.callId === EVERYONE) {
        session.sendMessage.toEveryone(message);
        ChatHistoryService.sendMessage({
          date: new Date().toISOString(),
          message,
          sender: session.callerName,
          vlrId
        }).then();
      } else {
        session.sendMessage.oneToOne(message, current.callId);
      }

      inputRef.current.value = '';
    }
  };

  const handlePopoverDismiss = () => popRef.current?.dismiss();

  const handleParticipantSelection = (callId: string) => {
    if (callId === EVERYONE) {
      setCurrent(DEFAULT_SELECTED_STATE);
      setChatParticipants(prev => {
        const participants = prev.participants.map(p => {
          if (p.callId === EVERYONE) {
            p.unread = 0;
          }

          return p;
        });

        return {
          ...prev,
          participants
        };
      });
    } else {
      const participantIndex = chatParticipants.participants.findIndex(p => p.callId === callId);
      const participant = chatParticipants.participants[participantIndex];
      setCurrent({callId, placeholder: participant.participantName, avatar: participant.avatar, left: false});
      setChatParticipants(prevState => {
        prevState.participants[participantIndex].unread = 0;
        // Move participant in front
        prevState.participants.splice(1, 0, prevState.participants.splice(participantIndex, 1)[0]);
        return prevState;
      });

      // participantsRef.current?.scrollIntoView();
    }
  };
  return (
    <>
      <IonPopover
        className="chat-popover"
        onDidDismiss={handlePopoverDismiss}
        ref={popRef}
        side="top"
        trigger={CHAT_PARTICIPANTS_POPOVER_ID}
        dismissOnSelect
        keepContentsMounted
      >
        <IonList>
          <IonListHeader>{t('chat.chatWith')}</IonListHeader>
          <IonItem
            button
            lines="none"
            onClick={() => handleParticipantSelection('everyone')}
          >
            <IonAvatar color="secondary" slot="start">
              <IonIcon icon={people}/>
            </IonAvatar>
            <IonText>{t('chat.everyone')}</IonText>
            {
              chatParticipants.participants.length && chatParticipants.participants[0].unread > 0 &&
              <IonBadge color="primary" slot="end">{chatParticipants.participants[0].unread}</IonBadge>
            }
          </IonItem>
          {
            chatParticipants.participants
              .filter(p => p.callId !== EVERYONE && !p.me)
              .map(({callId, participantName, avatar, unread}) => (
                <IonItem
                  button
                  key={callId}
                  lines="none"
                  onClick={() => handleParticipantSelection(callId)}
                >
                  <IonAvatar style={{backgroundColor: avatar.color}} slot="start">
                    <IonText>{avatar.text}</IonText>
                  </IonAvatar>
                  <IonText>{participantName}</IonText>
                  {unread > 0 && <IonBadge color="primary" slot="end">{unread}</IonBadge>}
                </IonItem>
              ))
          }
        </IonList>
      </IonPopover>

      <IonCard
        className="chat-card"
        color="light"
        style={{display: show ? 'flex' : 'none'}}
      >
        <IonCardContent className="chat-card-content">
          <div className="messages-container">
            {
              chatObj[current.callId].chat.map(({date, fromName, message}: IncomingMessage, index: number) => (
                <IonCard key={index} className="chat-message">
                  <IonCardContent>
                    <IonCardHeader>
                      <IonText className="chat-participant-name" color="dark">{fromName}</IonText>
                      <IonText className="chat-date" color="dark">{date}</IonText>
                    </IonCardHeader>

                    <IonText color="dark">{message}</IonText>
                  </IonCardContent>
                </IonCard>
              ))
            }
            <div ref={messagesRef}/>
          </div>

          <IonItem className="chat-item-input" lines="none">
            <IonButtons slot="start">
              <IonButton id={CHAT_PARTICIPANTS_POPOVER_ID} size="small">
                {
                  current.callId === EVERYONE ?
                    <IonAvatar color="secondary" slot="icon-only">
                      <IonIcon icon={people}/>
                    </IonAvatar> :
                    <IonAvatar style={{backgroundColor: current.avatar?.color}} slot="icon-only">
                      <IonText>{current.avatar?.text}</IonText>
                    </IonAvatar>
                }

                <IonBadge color="primary" className="chat-unread-all-badge" hidden={unreadMessages.initial === 0}>
                  {unreadMessages.initial}
                </IonBadge>
              </IonButton>
            </IonButtons>

            {
              !current.left ?
                <>
                  <IonInput
                    ref={inputRef}
                    placeholder={t('chat.placeholder')}
                    onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                  />

                  <IonButtons slot="end">
                    <IonButton onClick={handleSend} color="primary">
                      <IonIcon icon={paperPlaneOutline} slot="icon-only"/>
                    </IonButton>
                  </IonButtons>
                </> :
                <IonText className="chat-name-left">
                  <div className="chat-name">{current.placeholder}</div>
                  <div className="left">{t('chat.left')}</div>
                </IonText>
            }
          </IonItem>


        </IonCardContent>
      </IonCard>
    </>
  );
});

export default Chat;
