import React, {FC, useState} from 'react';
import './styles.scss';
import {IonBadge, IonButton, IonButtons, IonIcon} from '@ionic/react';
import {
  chatbubblesOutline,
  cogOutline,
  informationCircleOutline,
  micOffOutline,
  micOutline,
  scanOutline,
  shareSocialOutline,
  videocamOffOutline,
  videocamOutline
} from 'ionicons/icons';
import UserMediaModal from '../../../components/UserMediaModal';
import {useSelector} from 'react-redux';
import {ReduxSelectors} from '../../../redux/shared/types';
import Invite from '../../../components/Invite';
import SelectCamera from '../../../components/SelectCamera';
import RoomChangeLayout from '../../../components/RoomChangeLayout';
import {VertoLayout} from '../../../verto/types';
import RoomChangeStream from '../../../components/RoomChangeStream';
import {SharedStream} from '../../../shared/types';
import ChangeRoomStatus from '../../../components/ChangeRoomStatus';
import {useTranslation} from 'react-i18next';
// import zoomRoomIcon from "../../../images/icons/zoomRoom.svg"

type Props = {
  imHost: boolean | null;
  show: boolean;
  micMuted: boolean;
  camStopped: boolean;
  fullscreen: boolean;
  showChat: boolean;
  invitationUrl: string;
  isAdult: boolean;
  showStreamInfo: boolean;
  streamId?: number;
  isPrivate?: boolean;
  publicId?: string;
  onToggleMic: () => void;
  onToggleCam: (cam: string) => void;
  onFullscreen: () => void;
  onTheatreMode: () => void;
  onShowChat: (show: boolean) => void;
  onLayoutChange: (layout: VertoLayout) => void;
  onChangeStream: (stream: SharedStream) => void;
  onChangeRoomStatus: (value: boolean) => void;
  onShowStreamInfo: (value: boolean) => void;
};

const SideBarStream: FC<Props> = ({
                                    imHost,
                                    show,
                                    micMuted,
                                    camStopped,
                                    fullscreen,
                                    showChat,
                                    invitationUrl,
                                    streamId,
                                    isPrivate,
                                    isAdult,
                                    showStreamInfo,
                                    publicId,
                                    onToggleMic,
                                    onToggleCam,
                                    onFullscreen,
                                    onTheatreMode,
                                    onShowChat,
                                    onLayoutChange,
                                    onChangeStream,
                                    onChangeRoomStatus,
                                    onShowStreamInfo
                                  }: Props) => {
  const {t} = useTranslation();
  const {cam} = useSelector(({userMedia}: ReduxSelectors) => userMedia);
  const unreadMessages = useSelector(({unreadMessages}: ReduxSelectors) => unreadMessages);
  const {showDebugInfo} = useSelector(({profile}: ReduxSelectors) => profile);

  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const [showSelectCameraModal, setShowSelectCameraModal] = useState<boolean>(false);

  const handleCameraToggle = () => {
    if (cam === 'none') {
      setShowSelectCameraModal(true);
    } else {
      onToggleCam(cam);
    }
  };

  const handleSelectCameraCancel = () => {
    setShowSelectCameraModal(false);
  };

  const handleSelectCameraOk = (camId: string) => {
    handleSelectCameraCancel();
    onToggleCam(camId);
  };

  return (
    <div
      className={`stream-side-bar ${fullscreen ? 'fullscreen' : ''}`}
      style={{visibility: show ? 'visible' : 'hidden'}}>
      <IonButtons>
        {
          showDebugInfo &&
          <IonButton onClick={() => onShowStreamInfo(!showStreamInfo)}  title={t('roomSideBar.streamInfo')}>
            <IonIcon
              slot="icon-only"
              icon={informationCircleOutline}
              color={showStreamInfo ? 'success' : 'dark'}
            />
          </IonButton>
        }

        <IonButton
          className="side-bar-settings"
          title={t('roomSideBar.settings')}
          onClick={() => setShowSettingsModal(true)}>
          <IonIcon
            slot="icon-only"
            icon={cogOutline}
            color={showSettingsModal ? 'success' : 'dark'}
          />
        </IonButton>
        <IonButton
          className="invite-button"
          title={t('roomSideBar.share')}
          onClick={() => setShowInviteModal(true)}>
          <IonIcon
            slot="icon-only"
            icon={shareSocialOutline}
            color={showInviteModal ? 'success' : 'dark'}
          />
        </IonButton>
        {
          imHost &&
          <>
            {
              !isAdult &&
              <ChangeRoomStatus
                isPrivateInitial={isPrivate || false}
                onChangeRoomStatus={onChangeRoomStatus}/>
            }
            <RoomChangeStream streamId={streamId} onChangeStream={onChangeStream}/>
            <RoomChangeLayout onLayoutChange={onLayoutChange}/>
          </>
        }
        <IonButton
          className="chat-button"
          title={t('roomSideBar.chat')}
          onClick={() => onShowChat(!showChat)}
        >
          <IonIcon
            slot="icon-only"
            icon={chatbubblesOutline}
            color={showChat ? 'success' : 'dark'}
          />
          <IonBadge
            color="primary"
            className={!showChat ? 'show-delay' : ''}
            hidden={showChat || unreadMessages.accumulator === 0}>
            {unreadMessages.accumulator}
          </IonBadge>
        </IonButton>
        <IonButton onClick={handleCameraToggle} title={t('roomSideBar.cam')}>
          <IonIcon
            slot="icon-only"
            icon={camStopped ? videocamOffOutline : videocamOutline}
            color={camStopped ? 'dark' : 'success'}
          />
        </IonButton>
        <IonButton onClick={onToggleMic} title={t('roomSideBar.mic')}>
          <IonIcon
            slot="icon-only"
            icon={micMuted ? micOffOutline : micOutline}
            color={micMuted ? 'dark' : 'success'}
          />
        </IonButton>
        {/* <IonButton
          className="theatre-mode-button"
          title={t('roomSideBar.theatreMode')}
          onClick={() => onTheatreMode()}>
          <IonIcon
            slot="icon-only"
            icon={zoomRoomIcon}
            color={fullscreen ? 'success' : 'dark'}
          />
        </IonButton> */}
        <IonButton
          className="fullscreen-button"
          title={t('roomSideBar.fullscreen')}
          onClick={() => onFullscreen()}>
          <IonIcon
            slot="icon-only"
            icon={scanOutline}
            color={fullscreen ? 'success' : 'dark'}
          />
        </IonButton>
      </IonButtons>

      <UserMediaModal
        show={showSettingsModal}
        setShow={setShowSettingsModal}
      />

      <Invite
        roomPublicId={publicId}
        showPushInvite={(imHost && !isPrivate) || false}
        show={showInviteModal}
        url={invitationUrl}
        onClose={() => setShowInviteModal(false)}
      />

      <SelectCamera
        show={showSelectCameraModal}
        onOk={handleSelectCameraOk}
        onCancel={handleSelectCameraCancel}
      />
    </div>
  );
};

export default SideBarStream;
