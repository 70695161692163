import React from "react";
import "./styles.scss";
import Layout from "../../components/Layout";

const Stars: React.FC = () => {
  return (
    <Layout className="articles-page">
      <div>Stars</div>
    </Layout>
  );
};

export default Stars;
