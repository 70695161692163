import React, { FC } from 'react';
import './styles.scss';
import { IonCardContent } from '@ionic/react';
import { useTranslation } from 'react-i18next';

const TermsContent: FC = () => {
  const { i18n } = useTranslation();

  const renderPageText = () => {
    switch (i18n.language) {
      case 'ar':
        return (
          <>

            شروط خدمة 12ALL.TV
            تلفزيون الشامل1 ]
            اعتبارًا من 1 ديسمبر 2020
            ما هو مشمول في هذه الشروط
            نحن نعلم أنه من المغري تخطي شروط الخدمة هذه ، ولكن من المهم تحديد ما يمكن أن تتوقعه منا
            12ALL.TV  وما نتوقعه منك أثناء استخدامك لخدمات تلفزيون[١,٢ الشامل ]  ،
            تعكس شروط الخدمة  الطريقة التي تعمل بها شركة 12ALL.TV التجارية ، والقوانين التي تنطبق على شركتنا ، وبعض الأشياء التي كنا نعتقد دائمًا أنها صحيحة. نتيجة لذلك ، وتساعد شروط الخدمة  في 12ALL.TV
            علاقة معك أثناء تفاعلك مع خدماتنا. على سبيل المثال ، تتضمن هذه المصطلحات عناوين تحديد
            12ALL.TV
            الموضوعات التالية
            ما يمكن أن تتوقعه منا ، والذي يصف كيفية تقديم خدماتنا وتطويرها
            ما نتوقعه منك ، والذي يحدد قواعد معينة لاستخدام خدماتنا

            المحتوى الموجود في خدمات   12 ALL.TV لذي يصف حقوق الملكية الفكرية للمحتوى الموجود  في خدماتنا
            سواء كان هذا المحتوى ملكًا لك أو ل  12 ALL.TV  أو لغيره
            وفي حالة وجود  اي مشاكل أو خلافات ، وهنانوضح الحقوق القانونية الأخرى التي لديك ، وما يمكن توقعه في حالة انتهاك شخص  لهذه الشروط والحقوق
            يعد فهم هذه الشروط أمرًا مهمًا لأنه لاستخدام خدماتنا ، يجب عليك قبول هذه الشروط.
            إلى جانب هذه الشروط ، ننشر أيضًا سياسة الخصوصية. على الرغم من أنها ليست جزءًا من هذه البنود  فإننا نشجعك على قراءتها لفهم كيفية تحديث معلوماتك وإدارتها وتصديرها وحذفها بشكل أفضل
            مقدم الخدمة
            في المنطقة الاقتصادية الأوروبية (EEA) ، يتم تقديم خدمات  12 ALL.TV  بواسطتنا ، وأنت تتعاقد مع شركة:تلفزيون[١,٢ الشامل ]  المحدودة
            تأسست وتعمل بموجب القوانين البلغاريا (برقم مسجل: 206218805
            العنوان
            6 شارع انجيستا
            صوفيا 1527
            بلغاريا
            متطلبات العمر
            إذا كان عمرك أقل من 16 عامًا ، فيجب أن تحصل على إذن والديك أو الوصي القانوني لاستخدامك
            إذا كنت أحد الوالدين أو وصيًا قانونيًا ، وسمحت لطفلك باستخدام الخدمات ، فإن هذه البنود تنطبق عليك وأنت مسؤول عن نشاط طفلك على.حساب
            12ALL.TV.
            يرجى مطالبة والديك أو الوصي القانوني بقراءة هذه الشروط معك.
            علاقتك مع   12 ALL.TV
            تساعد هذه المصطلحات في تحديد العلاقة بينك وبين  12 ALL.TV  . بشكل عام ، نمنحك إذنًا لاستخدام خدماتنا إذا كنت توافق على اتباع هذه الشروط ، والتي تعكس كيفية عمل
            12 ALL.TV  التجارية وكيف نربح المال. عندما نتحدث عن " 12 ALL.TV  و "نحن" و "لنا" و "خاصتنا" ، فإننا نعني واحد اثنين OOD  بلغاريا والشركات التابعة لها.
            ما الذي يمكن أن تتوقعه منا
            الخدمات المقدمة التي تخضع لهذه الشروط ، بما في ذلك:
            الوصول إلى تلفزيون واحد اتنين و جميع تطبيقات التلفزيون والعلامات التجارية لتلفزيون الويب: MYTOTAL.TV و BIZON.TV و BGCN.TV
            إمكانية مشاركة مقاطع الفيديو الخاصة بك وتغذية الكاميرا وشاشة وحدة التحكم في الألعاب والقنوات التلفزيونية الحية من خلال خدمة 12ALL Watch Party.
            تم تصميم خدماتنا للعمل معًا ، مما يسهل عليك الانتقال من نشاط إلى آخر.
            تحسين خدمات ALL.TV12
            نعمل باستمرار على تطوير تقنيات وميزات جديدة لتحسين خدماتنا. كجزء من هذا التحسين المستمر ، نضيف أحيانًا ميزات ووظائف أو نزيلها ، ونزيد حدود خدماتنا أو نخفضها ، ونبدأ في تقديم خدمات جديدة أو نتوقف عن تقديم الخدمات القديمة.
            نحافظ على برنامج بحث صارم عن المنتج ، لذلك قبل أن نتغير أو نتوقف عن تقديم خدمة ما ، فإننا نأخذ بعين الاعتبار اهتماماتك كمستخدم وتوقعاتك المعقولة والتأثير المحتمل عليك وعلى الآخرين.
            نحن فقط نغير أو نتوقف عن تقديم الخدمات لأسباب وجيهة ، مثل تحسين الأداء أو الأمان ، أو الامتثال للقانون ، أو منع الأنشطة غير القانونية أو إساءة الاستخدام ، أو لنعكس التطورات التقنية ، أو  اضافة ميزة أو نزيل خدمة بأكملها لم تعد شائعة بدرجة كافية أو غيراقتصادية للعرض.
            ما نتوقعه منك
            اتباع  الشروط والبنود الإضافية الخاصة بالخدمة
            يستمر الإذن الذي نمنحه لك لاستخدام خدماتنا طالما أنك تفي بمسؤولياتك في هذه الشروط.
            نوفر لك أيضًا سياسات متنوعة وموارد أخرى للإجابة على الأسئلة الشائعة ولتحديد التوقعات بشأن استخدام خدماتنا.
            تتضمن هذه الموارد سياسة الخصوصية الخاصة بنا وصفحات أخرى يمكن الوصول إليها من موقعنا.
            على الرغم من أننا نمنحك الإذن باستخدام خدماتنا ، إلا أننا نحتفظ بأي حقوق ملكية فكرية لدينا في الخدمات.
            احترم الاخرين
            تسمح لك خدماتنا بالتفاعل مع الآخرين.
            نريد الحفاظ على بيئة محترمة للجميع ، مما يعني أنه يجب عليك اتباع قواعد السلوك الأساسية التالية:
            التوافق مع القوانين المعمول بها
            احترام حقوق الآخرين ، بما في ذلك الخصوصية وحقوق الملكية الفكرية
            لا تسيء أو تؤذي نفسك أو الآخرين (أو تهدد أو تشجع مثل هذه الإساءة أو الأذى)
            لا تسيء استخدام الخدمات  المقدمة لك أو تضر بها أو تتدخل فيها أو تعطلها
            إذا وجدت أن الآخرين لا يتبعون هذه القواعد ، فإن خدماتنا تسمح لك بالإبلاغ عن  اي إساءة للمحتوي.
            إذن لاستخدام المحتوى الخاص بك
            تم تصميم بعض خدماتنا للسماح لك بمشاركة المحتوى الخاص بك. لا يلزمك تقديم أي محتوى لخدماتنا ولك مطلق الحرية في اختيار المحتوى الذي تريد تقديمه. إذا اخترت تحميل المحتوى أو مشاركته ، فيرجى التأكد من أن لديك الحقوق اللازمة للقيام بذلك وأن المحتوى قانوني.
            رخصة
            يظل المحتوى الخاص بك ملكًا لك ، مما يعني أنك تحتفظ بأي حقوق ملكية فكرية لديك علي المحتوى الخاص بك. على سبيل المثال ، لديك حقوق ملكية فكرية في المحتوى الإبداعي الذي تنشئه ، مثل المراجعات التي تكتبها. أو قد يكون لديك الحق في مشاركة محتوى إبداعي لشخص آخر إذا منحك الإذن بذلك.
            نحتاج إلى إذنك إذا كانت حقوق الملكية الفكرية الخاصة بك تقيد استخدامنا للمحتوى الخاص بك. أنت
            تقدم  بهذا الإذن من خلال هذا الترخيص
            12ALL.TV.
            ما يتم تغطيته
            يغطي هذا الترخيص المحتوى الخاص بك إذا كان هذا المحتوى محميًا بحقوق الملكية الفكرية.
            لديك الحق في طلب محتوى تشاركه ، ليتم تقديمه (المحتوى المدفوع) وسيتم دفع الدخل لك بعد خصم رسوم الناشر لدينا.
            ما لم تتم تغطيته
            لا يؤثر هذا الترخيص على حقوق حماية البيانات - إنه يتعلق فقط بحقوق الملكية الفكرية الخاصة بك.
            مجال
            هذا الترخيص هو:
            جميع أنحاء العالم ، مما يعني أنه صالح في أي مكان في العالم
            غير حصري ، مما يعني أنه يمكنك ترخيص المحتوى الخاص بك للآخرين
            حقوق
            يسمح هذا الترخيص لـ  12 ALL.TV  بما يلي:
            استضافة المحتوى الخاص بك وإعادة إنتاجه وتوزيعه وتوصيله واستخدامه
            على سبيل المثال ،حفظ المحتوى الخاص بك على أنظمتنا وإتاحته لك  في أي مكان تذهب إليه
            نشر المحتوى الخاص بك أو أدائه بشكل عام أو عرضه علنًا ، إذا كنت قد جعلته مرئيًا للآخرين
            تعديل المحتوى الخاص بك ، مثل إعادة تنسيقه
            ترخيص هذه الحقوق من الباطن من أجل:
            يسمح المستخدمون الآخرون للخدمات بالعمل كما تم تصميمها ، مثل تمكينك من مشاركة الصور مع الأشخاص الذين تختارهم
            متعاقدونا الذين وقعوا اتفاقيات معنا تتوافق مع هذه الشروط ، فقط للأغراض المحدودة الموضحة في قسم الغرض أدناه
            غرض
            هذا الترخيص لغرض محدود وهو:
            تشغيل الخدمات وتحسينها ، مما يعني السماح للخدمات بالعمل على النحو المصمم له وإنشاء ميزات ووظائف جديدة. يتضمن ذلك استخدام الأنظمة الآلية والخوارزميات لتحليل المحتوى الخاص بك:
            للمحتوى غير المرغوب فيه والبرامج الضارة والمحتوى غير القانوني
            لتخصيص خدماتنا لك ، مثل تقديم التوصيات ونتائج البحث المخصصة والمحتوى والإعلانات التي يمكنك تغييرها أو إيقاف تشغيلها في إعدادات الإعلانات
            تطوير تقنيات وخدمات جديدة لـ  12 ALL.TV  بما يتوافق مع هذه الشروط
            المدة
            يستمر هذا الترخيص طالما أن المحتوى الخاص بك محمي بموجب حقوق الملكية الفكرية.
            إذا قمت بإزالة أي محتوى مشمول بهذا الترخيص من خدماتنا ، فستتوقف أنظمتنا عن إتاحة هذا المحتوى للجمهور في فترة زمنية معقولة.
            هناك بعض الاستثناءات:
            إذا قمت بالفعل بمشاركة المحتوى الخاص بك مع الآخرين قبل إزالته.
            باستخدام خدمات ال ALL.TV12
            علي الحساب الخاص بك
            و كنت مستوفي للشروط  لدينا ، يمكنك إنشاء حساب  12 ALL.TV  لراحتك.
            طلب بعض الخدمات  يجب أن يكون لديك حساب  12 ALL.TV  لكي يعمل.
            أنت مسؤول عما تفعله بحساب  12 ALL.TV    الخاص بك ، بما في ذلك اتخاذ خطوات معقولة للحفاظ على الحساب الخاص بك آمنًا.
            استخدام خدمات  12 ALL.TV  نيابة عن منظمة أو شركة
            تستفيد العديد من المؤسسات ، مثل الشركات والمؤسسات غير الربحية والمدارس ، من خدماتنا. لاستخدام خدماتنا نيابة عن منظمة:
            يجب أن يوافق ممثل  اوالمفوض لتلك المنظمة على هذه الشروط
            يجوز لمسؤول مؤسستك تعيين حساب 12 ALL.TV لك. قد يطلب منك هذا المسؤول اتباع قواعد إضافية وقد يكون قادرًا على الوصول إلى حساب 12 ALL.TV الخاص بك أو تعطيله
            إذا كنت مقيمًا في الاتحاد الأوروبي ، فلن تؤثر هذه الشروط على الحقوق التي قد تتمتع بها بصفتك مستخدمًا تجاريًا لخدمات الوساطة عبر الإنترنت - بما في ذلك المنصات عبر الإنترنت مثل
            12 ALL.TV  بموجب لائحة الاتحاد الأوروبي المنصة إلى الأعمال. .
            الاتصالات المتعلقة بالخدمة
            لتزويدك بخدماتنا ، نرسل إليك أحيانًا إعلانات الخدمة وغيرها من المعلومات. لمعرفة المزيد حول كيفية تواصلنا معك ، راجع سياسة خصوصية 12 ALL.TV s.
            إذا اخترت أن تقدم لنا ملاحظات ، مثل اقتراحات لتحسين خدماتنا ، فقد نتصرف بناءً على ملاحظاتك دون التزام تجاهك.
            المحتوى في خدمات12 ALL.TV
            المحتوى الخاص بك
            تمنحك بعض خدماتنا الفرصة لإتاحة المحتوى الخاص بك للجمهور - على سبيل المثال ، يمكنك بث مراجعة لمنتج أو مطعم قمت بإعداد منشورله او مدونة  او فيديو قمت بإنشائه
            اقرأ القسم التالي لمعرفة سبب وكيفية إزالة المحتوى الذي أنشأه المستخدمون من خدماتنا:
            إذا اعتقدنا بشكل عقلاني أن أيًا من المحتوى الخاص بك
            (1) ينتهك هذه الشروط أو السياسات
            (2) ينتهك القانون المعمول به ،
            (3) قد يضر مستخدمينا أو الأطراف الثالثة أو ALL.TV 12، فإننا نحتفظ بالحق في إيقاف اوحظر بعض أو كل هذا المحتوى وفقًا للقانون المعمول به. تشمل الأمثلة المواد الإباحية المتعلقة بالأطفال والمحتوى الذي يسهل الاتجار بالبشر أو المضايقات والمحتوى الذي ينتهك حقوق الملكية الفكرية لشخص آخر.
            إذا كنت تعتقد أن شخصًا ما ينتهك حقوق الملكية الفكرية الخاصة بك ، فيمكنك إرسال إشعار إلينا بهذا الانتهاك وسنتخذ الإجراء المناسب. على سبيل المثال ، نقوم بتعليق أو إغلاق حسابات منتهكي حقوق النشر المتكررين ALL TV 12
            جميع محتويات التلفزيون
            تتضمن بعض خدماتنا محتوى ينتمي إلى ALL TV 12. يمكنك استخدام محتوى ALL.TV 12 على النحو الذي تسمح به هذه الشروط ، لكننا نحتفظ بأي حقوق ملكية فكرية نمتلكها في المحتوى الخاص بنا.
            لا تقم بإزالة أو إخفاء أو تغيير أي من علامتنا التجارية أو شعاراتنا أو إشعاراتنا القانونية.
            إذا كنت تريد استخدام علامتنا التجارية أو شعاراتنا.
            محتويات أخرى
            أخيرًا ، تمنحك خدمتنا إمكانية الوصول إلى المحتوى الذي يخص أشخاصًا أو مؤسسات أخرى - على سبيل المثال ، وصف مالك متجر نشاطه التجاري. ولا يجوز لك استخدام هذا المحتوى دون إذن ذلك الشخص أو المنظمة ، أو على النحو الذي يسمح به القانون. الآراء المعبر بها في محتوى الأشخاص أو المنظمات الأخرى تخصهم ، ولا تعكس بالضرورة آراء ALL TV 12
            في حالة وجود مشاكل أو خلافات
            بموجب القانون ، لديك الحق في
            (1) جودة خدمة معينة
            (2) طرق لإصلاح المشكلات إذا ساءت الأمور.
            لا تقيد هذه الشروط أو تلغي أيًا من هذه الحقوق.
            على سبيل المثال ، إذا كنت مستهلكًا ، فإنك ستستمر في التمتع بجميع الحقوق القانونية الممنوحة للمستهلكين بموجب القانون الساري.
            ضمان
            نحن نقدم خدماتنا باستخدام مهارة ورعاية معقولة. إذا لم نلبي مستوى الجودة المتوقع ، فأنت توافق على إخبارنا وسنعمل معك لمحاولة حل المشكلة.
            إخلاء المسؤلية
            يتم توفير التزاماتنا الوحيدة التي نتعهد بها بشأن خدماتنا (بما في ذلك المحتوى الموجود في الخدمات أو الوظائف المحددة لخدماتنا أو موثوقيتها أو توفرها أو قدرتها على تلبية احتياجاتك) بموجب القوانين المعمول بها.
            لا نتعهد بأي التزامات أخرى بخصوص خدماتنا.
            المطلوب
            لمستخدمي الأعمال والمؤسسات فقط
            إذا كنت مستخدمًا تجاريًا أو مؤسسة ، فبالقدر الذي يسمح به القانون الساري:
            ستقوم بتعويض 12ALL.TV ومديريها ومسؤوليها وموظفيها ومقاوليها عن أي إجراءات قانونية لطرف ثالث (بما في ذلك الإجراءات التي تتخذها السلطات الحكومية) الناشئة عن أو المتعلقة باستخدامك الغير قانوني للخدمات أو انتهاك هذه الشروط. يغطي هذا التعويض أي مصاريف تنشأ عن المطالبات والخسائر والأضرار والأحكام والغرامات وتكاليف التقاضي والرسوم القانونية.
            12ALL.TV لن تكون مسؤولة عن الالتزامات التالية:
            خسارة الأرباح أو الإيرادات أو فرص العمل أو الشهرة أو المدخرات المتوقعة
            الخسارة الغير مباشرة أو اللاحقة
            التعويضات التأديبية
            اتخاذ الإجراءات في حالة حدوث مشاكل
            قبل اتخاذ الإجراء كما هو موضح أدناه ، سنقدم لك إشعارًا مسبقًا عندما يكون ذلك ممكنًا بشكل معقول ، وسنصف سبب إجراءنا ، ونمنحك فرصة لإصلاح المشكلة ، ما لم نعتقد بشكل معقول أن القيام بذلك سيؤدي إلى:
            تسبب ضررًا أو مسؤولية تجاه مستخدم أو طرف ثالث أو  12ALL.TV
            تنتهك القانون أو أمر سلطة إنفاذ القانون
            المساومة على التحقيق
            تعريض تشغيل خدماتنا أو تكاملها أو أمنها للخطر
            إزالة المحتوى الخاص بك
            إذا اعتقدنا بشكل عقلاني أن أيًا من المحتوىات الخاص بك
            (1) ينتهك هذه الشروط
            (2) ينتهك القانون المعمول به
            (3) يمكن أن يضر مستخدمينا ، أو الأطراف الثالثة ، أو12ALL.TV ، فإننا نحتفظ بالحق في إيقاف او حظر جزء أو كل  المحتوى وفقًا للقانون المعمول به.
            تشمل الأمثلة المواد الإباحية المتعلقة بالأطفال والمحتوى الذي يسهل الاتجار بالبشر أو المضايقات والمحتوى الذي ينتهك حقوق الملكية الفكرية لشخص آخر.
            تعليق أو إنهاء وصولك إلى خدمات 12ALL.TV
            ونحتفظ بالحق في تعليق أو إنهاء وصولك إلى الخدمات أو حذف حساب 12ALL.TV الخاص بك إذا حدث أي من هذه الأشياء:
            عند قيامك بخرق هذه الشروط بشكل متكرر أو مادي
            نحن سانقوم بلامتثال للمتطلبات القانونية أو بأمر المحكمة
            ونعتقد   أن سلوكك يسبب الضررًا أو ضرر تجاه مستخدم اخر أو طرف ثالث أو12ALL.TV - على سبيل المثال ، عن طريق القرصنة أو التصيد الاحتيالي أو المضايقة أو إرسال بريد عشوائي أو تضليل الآخرين أو كشط محتوى لا يخصك
            إذا كنت تعتقد أن حساب 12ALL.TV الخاص بك قد تم تعليقه أو إنهاؤه عن طريق الخطأ ، فيمكنك الاستئناف.
            التعامل مع طلبات البيانات الخاصة بك
            يعد احترام خصوصية وأمن بياناتك أساس نهجنا في الاستجابة لطلبات الكشف عن البيانات. عندما نتلقى طلبات الكشف عن البيانات ، يقوم فريقنا بمراجعتها للتأكد من أنها تفي بالمتطلبات القانونية وسياسات الإفصاح عن البيانات الخاصة بـ 12ALL.TV One Two All OOD ، يصل إلى البيانات ويكشف عنها  بما في ذلك الاتصالات ، وفقًا لقوانين بلغاريا ، وقانون الاتحاد الأوروبي المعمول به في بلغاريا.
            تسوية المنازعات بالقانون وفي المحاكم والقضاة
            للحصول على معلومات حول كيفية الاتصال بـ 12ALL.TV ، يرجى زيارة صفحة الاتصال الخاصة بنا.
            إذا كنت مقيماً في المنطقة الاقتصادية الأوروبية (EEA) أو سويسرا أو كنت مقيماً بها ، فإن هذه الشروط وعلاقتك بـ 12ALL.TV بموجب هذه الشروط ، تخضع لقوانين بلد إقامتك ، وأنت يمكن لك رفع المنازعات القانونية في المحاكم المحلية الخاصة بك.
            إذا كنت مستهلكًا تعيش في المنطقة الاقتصادية الأوروبية ، فيمكنك أيضًا رفع نزاعات بشأن عمليات الشراء عبر الإنترنت باستخدام النظام الأساسي لحل النزاعات عبر الإنترنت التابع للمفوضية الأوروبية  والذي نقبله إذا كان القانون يقتضي ذلك.
            حول هذه الشروط
            بموجب القانون ، لديك حقوق معينة لا يمكن تقييدها بموجب عقد مثل بنود الخدمة هذه. لا تهدف هذه الشروط بأي حال من الأحوال إلى تقييد هذه الحقوق.
            تصف هذه المصطلحات العلاقة بينك وبين  12ALL.TV إنهم لا ينشئون أي حقوق قانونية لأشخاص أو منظمات أخرى ، حتى لو استفاد الآخرون من هذه العلاقة بموجب هذه الشروط.
            إذا اتضح أن مصطلحًا معينًا غير صالح أو قابل للتنفيذ ، فلن يؤثر ذلك على أي شروط أخرى.
            إذا لم تتبع هذه البنود ، ولم نتخذ أي إجراء على الفور ، فهذا لا يعني أننا نتنازل عن أي حقوق قد نمتلكها ، مثل اتخاذ إجراء في المستقبل
            قد نقوم بتحديث هذه الشروط
            (1) لتعكس التغييرات في خدماتنا أو كيفية قيامنا بالأعمال - على سبيل المثال ، عندما نضيف خدمات أو ميزات أو تقنيات أو أسعارًا أو مزايا جديدة أو نزيل القديمة منها
            (2) للأغراض القانونية ، أسباب تنظيمية أو أمنية
            (3) لمنع إساءة الاستخدام أو الأذى.
            إذا قمنا بتغيير هذه الشروط ماديًا ، فسنقدم لك إشعارًا مسبقًا معقولاً وفرصة لمراجعة التغييرات ، باستثناء
            (1) عندما نطلق خدمة أو ميزة جديدة
            (2) في المواقف العاجلة ، مثل منع إساءة الاستخدام المستمر أو الاستجابة للمتطلبات القانونية. إذا كنت لا توافق على البنود الجديدة ، يجب عليك إزالة المحتوى الخاص بك والتوقف عن استخدام الخدمات.
            يمكنك أيضًا إنهاء علاقتك معنا في أي وقت عن طريق إغلاق حسابك.

          </>
        )
      case 'en':
      default:
        return (
          <>
            <header>
              <h1>12ALL.TV Terms of Service</h1>
              <div>Effective December 1, 2020</div>
            </header>
            <main>
              <article>
                <h3>What’s covered in these terms</h3>
                <p>
                  <b>
                    We know it’s tempting to skip these Terms of Service, but it’s important to establish what you can
                    expect from us as you use 12ALL.TV services, and what we expect from you.
                  </b>
                </p>
                <p>
                  These Terms of Service reflect the way 12ALL.TV’s business works, the laws that apply to our company,
                  and
                  certain things we’ve always believed to be true. As a result, these Terms of Service help define
                  12ALL.TV’s
                  relationship with you as you interact with our services. For example, these terms include the following
                  topic headings:
                </p>
                <ul>
                  <li>What you can expect from us, which describes how we provide and develop our services</li>
                  <li>What we expect from you, which establishes certain rules for using our services</li>
                  <li>
                    Content in 12ALL.TV services, which describes the intellectual property rights to the content you find
                    in our services — whether that content belongs to you, 12ALL.TV, or others
                  </li>
                  <li>In case of problems or disagreements, which describes other legal rights you have, and what to
                    expect
                    in
                    case someone violates these terms
                  </li>
                </ul>
                <p>Understanding these terms is important because, to use our services, you must accept these terms.</p>
                <p>
                  Besides these terms, we also publish a Privacy Policy. Although it’s not part of these terms, we
                  encourage
                  you to read it to better understand how you can update, manage, export, and delete your information.
                </p>
              </article>
              <article>
                <h3>Service provider</h3>
                <p>In the European Economic Area (EEA), 12ALL.TV services are provided by, and you’re contracting
                  with:</p>
                <p>One Two All OOD</p>
                <p>incorporated and operating under the laws of Bulgaria (Registered Number: 206218805)</p>
                <div>6 Angista Street</div>
                <div>Sofia 1527</div>
                <div>Bulgaria</div>
              </article>
              <article>
                <h3>Age requirements</h3>
                <p>
                  If you’re under the 16 years old, you must have your parent or legal guardian’s permission to use a
                  12ALL.TV Account. Please have your parent or legal guardian read these terms with you.
                </p>
                <p>
                  If you’re a parent or legal guardian, and you allow your child to use the services, then these terms
                  apply
                  to you and you’re responsible for your child’s activity on the services.
                </p>
              </article>
              <article>
                <h3>Your relationship with 12ALL.TV</h3>
                <p>
                  These terms help define the relationship between you and 12ALL.TV. Broadly speaking, we give you
                  permission to use our services if you agree to follow these terms, which reflect how 12ALL.TV’s business
                  works and how we earn money. When we speak of “12ALL.TV,” “we,” “us,” and “our,” we mean One Two All
                  OOD,
                  Bulgaria and its affiliates.
                </p>
              </article>
              <article>
                <h3>What you can expect from us</h3>
                <p>Provided services that are subject to these terms, including:</p>
                <ul>
                  <li>Access to 12ALL TV Apps and WebTV brands: MYTOTAL.TV, BIZON.TV and BGCN.TV</li>
                  <li>
                    Possibility to share your own Generated Video, Camera feed, Game console screen and live TV channels
                    through 12ALL Watch Party service.
                  </li>
                </ul>
                <p>
                  Our services are designed to work together, making it easier for you to move from one activity to the
                  next.
                </p>
              </article>
              <article>
                <h3>Improve 12ALL.TV services</h3>
                <p>
                  We’re constantly developing new technologies and features to improve our services. As part of this
                  continual improvement, we sometimes add or remove features and functionalities, increase or decrease
                  limits to our services, and start offering new services or stop offering old ones.
                </p>
                <p>
                  We maintain a rigorous product research program, so before we change or stop offering a service, we
                  carefully consider your interests as a user, your reasonable expectations, and the potential impact on
                  you
                  and others. We only change or stop offering services for valid reasons, such as to improve performance
                  or
                  security, to comply with law, to prevent illegal activities or abuse, to reflect technical developments,
                  or because a feature or an entire service is no longer popular enough or economical to offer.
                </p>
              </article>
              <article>
                <h3>What we expect from you</h3>
                <section>
                  <h4>Follow these terms and service-specific additional terms</h4>
                  <p>
                    The permission we give you to use our services continues as long as you meet your responsibilities
                    in <b>these terms</b>.
                  </p>
                  <p>
                    We also make various policies, and other resources available to you to answer common questions and to
                    set
                    expectations about using our services. These resources include our Privacy Policy, and other pages
                    accessible from our site.
                  </p>
                  <p>
                    Although we give you permission to use our services, we retain any intellectual property rights we
                    have
                    in
                    the services.
                  </p>
                </section>
                <section>
                  <h4>Respect others</h4>
                  <p>
                    Our services allow you to interact with others. We want to maintain a respectful environment for
                    everyone, which means you must follow these basic rules of conduct:
                  </p>
                  <ul>
                    <li>comply with applicable laws</li>
                    <li>respect the rights of others, including privacy and intellectual property rights</li>
                    <li>don’t abuse or harm others or yourself (or threaten or encourage such abuse or harm)</li>
                    <li>don’t abuse, harm, interfere with, or disrupt the services</li>
                  </ul>
                  <p>If you find that others aren’t following these rules, our services allow you to report abuse.</p>
                </section>
                <section>
                  <h4>Permission to use your content</h4>
                  <p>
                    Some of our services are designed to let you share your content. You have no obligation to provide any
                    content to our services and you’re free to choose the content that you want to provide. If you choose
                    to
                    upload or share content, please make sure you have the necessary rights to do so and that the content
                    is
                    lawful.
                  </p>
                </section>
              </article>
              <article>
                <h3>License</h3>
                <p>
                  Your content remains yours, which means that you retain any intellectual property rights that you have
                  in
                  your content. For example, you have intellectual property rights in the creative content you make, such
                  as
                  reviews you write. Or you may have the right to share someone else’s creative content if they’ve given
                  you
                  their permission.
                </p>
                <p>
                  We need your permission if your intellectual property rights restrict our use of your content. You
                  provide
                  12ALL.TV with that permission through this license.
                </p>
                <section>
                  <h4>What’s covered</h4>
                  <p>This license covers your content if that content is protected by intellectual property rights.</p>
                  <p>
                    You have the right to request for a content you share, to be paid (paid content) and the income will
                    be
                    paid to you after deduction of our publisher fees.
                  </p>
                </section>
                <section>
                  <h4>What’s not covered</h4>
                  <p>
                    This license doesn’t affect your data protection rights — it’s only about your intellectual property
                    rights.
                  </p>
                </section>
                <section>
                  <h4>Scope</h4>
                  <p>This license is:</p>
                  <ul>
                    <li>worldwide, which means it’s valid anywhere in the world</li>
                    <li>non-exclusive, which means you can license your content to others</li>
                  </ul>
                </section>
                <section>
                  <h4>Rights</h4>
                  <p>This license allows 12ALL.TV to:</p>
                  <ul>
                    <li>
                      host, reproduce, distribute, communicate, and use your content — for example, to save your content
                      on
                      our systems and make it accessible from anywhere you go
                    </li>
                    <li>
                      publish, publicly perform, or publicly display your content, if you’ve made it visible to others
                    </li>
                    <li>modify your content, such as reformatting it</li>
                    <li>
                      sublicense these rights to:
                      <ul>
                        <li>
                          other users to allow the services to work as designed, such as enabling you to share photos with
                          people you choose
                        </li>
                        <li>
                          our contractors who’ve signed agreements with us that are consistent with these terms, only for
                          the limited purposes described in the Purpose section below
                        </li>
                      </ul>
                    </li>
                  </ul>
                </section>
                <section>
                  <h4>Purpose</h4>
                  <p>This license is for the limited purpose of:</p>
                  <ul>
                    <li>
                      <b>operating and improving the services</b>, which means allowing the services to work as designed
                      and
                      creating new features and functionalities. This includes using automated systems and algorithms to
                      analyze your content:
                      <ul>
                        <li>for spam, malware, and illegal content</li>
                        <li>
                          to customize our services for you, such as providing recommendations and personalized search
                          results, content, and ads (which you can change or turn off in Ads Settings)
                        </li>
                      </ul>
                    </li>
                    <li>
                      <b>developing new technologies</b> and services for 12ALL.TV consistent with these terms
                    </li>
                  </ul>
                </section>
                <section>
                  <h4>Duration</h4>
                  <p>This license lasts for as long as your content is protected by intellectual property rights.</p>
                  <p>
                    If you remove from our services any content that’s covered by this license, then our systems will stop
                    making that content publicly available in a reasonable amount of time. There is some exception:
                    <ul>
                      <li>If you already shared your content with others before removing it.</li>
                    </ul>
                  </p>
                </section>
              </article>

              <h2>Using 12ALL.TV services</h2>
              <article>
                <h3>Your 12ALL.TV Account</h3>
                <p>
                  If you meet our age requirements you can create a 12ALL.TV Account for your convenience. Some services
                  require that you have a 12ALL.TV Account in order to work.
                </p>
                <p>
                  You’re responsible for what you do with your 12ALL.TV Account, including taking reasonable steps to keep
                  your 12ALL.TV Account secure.
                </p>
              </article>
              <article>
                <h3>Using 12ALL.TV services on behalf of an organization or business</h3>
                <p>
                  Many organizations, such as businesses, non-profits, and schools, take advantage of our services. To use
                  our services on behalf of an organization:
                </p>
                <ul>
                  <li>an authorized representative of that organization must agree to these terms</li>
                  <li>
                    your organization’s administrator may assign a 12ALL.TV Account to you. That administrator might
                    require you to follow additional rules and may be able to access or disable your 12ALL.TV Account
                  </li>
                </ul>
                <p>
                  If you’re based in the European Union, then these terms don’t affect the rights you may have as a
                  business
                  user of online intermediation services — including online platforms such as 12ALL.TV Play — under the EU
                  Platform-to-Business Regulation.
                </p>
              </article>
              <article>
                <h3>Service-related communications</h3>
                <p>
                  To provide you with our services, we sometimes send you service announcements and other information. To
                  learn more about how we communicate with you, see 12ALL.TV’s Privacy Policy.
                </p>
                <p>
                  If you choose to give us feedback, such as suggestions to improve our services, we may act on your
                  feedback without obligation to you.
                </p>
              </article>

              <h2>Content in 12ALL.TV services</h2>
              <article>
                <h3>Your content</h3>
                <p>
                  Some of our services give you the opportunity to make your content publicly available — for example, you
                  might broadcast a product or restaurant review that you prepared a video blog post that you created.
                </p>
                <ul>
                  <li>
                    <div>Read the following section to learn why and how we might remove user-generated content from our
                      services:
                    </div>
                    <p>
                      If we reasonably believe that any of your content (1) breaches these terms or policies, (2) violates
                      applicable law, or (3) could harm our users, third parties, or 12ALL.TV, then we reserve the right
                      to
                      stop/block some or all of that content in accordance with applicable law. Examples include child
                      pornography, content that facilitates human trafficking or harassment, and content that infringes
                      someone else’s intellectual property rights.
                    </p>
                  </li>
                </ul>
                <p>
                  If you think someone is infringing your intellectual property rights, you can send us notice of the
                  infringement and we’ll take appropriate action. For example, we suspend or close the 12ALL.TV Accounts
                  of
                  repeat copyright infringers.
                </p>
              </article>
              <article>
                <h3>12ALL.TV content</h3>
                <p>
                  Some of our services include content that belongs to 12ALL.TV. You may use 12ALL.TV’s content as allowed
                  by these terms, but we retain any intellectual property rights that we have in our content. Don’t
                  remove,
                  obscure, or alter any of our branding, logos, or legal notices. If you want to use our branding or
                  logos.
                </p>
              </article>
              <article>
                <h3>Other content</h3>
                <p>
                  Finally, our service give you access to content that belongs to other people or organizations — for
                  example, a store owner’s description of their own business. You may not use this content without that
                  person or organization’s permission, or as otherwise allowed by law. The views expressed in other people
                  or organizations’ content are theirs, and don’t necessarily reflect 12ALL.TV’s views.
                </p>
              </article>
              <article>
                <h3>In case of problems or disagreements</h3>
                <p>
                  By law, you have the right to (1) a certain quality of service, and (2) ways to fix problems if things
                  go
                  wrong. These terms don’t limit or take away any of those rights. For example, if you’re a consumer, then
                  you continue to enjoy all legal rights granted to consumers under applicable law.
                </p>
              </article>
              <article>
                <h3>Warranty</h3>
                <p>
                  We provide our services using reasonable skill and care. If we don’t meet the quality level expected,
                  you
                  agree to tell us and we’ll work with you to try to resolve the issue.
                </p>
              </article>
              <article>
                <h3>Disclaimers</h3>
                <p>
                  The only commitments we make about our services (including the content in the services, the specific
                  functions of our services, or their reliability, availability, or ability to meet your needs) are
                  provided
                  under applicable laws. We don’t make any other commitments about our services.
                </p>
              </article>
              <article>
                <h3>Liabilities</h3>
                <section>
                  <h4>For business users and organizations only</h4>
                  <p>If you’re a business user or organization, then to the extent allowed by applicable law:</p>
                  <ul>
                    <li>
                      You’ll indemnify 12ALL.TV and its directors, officers, employees, and contractors for any
                      third-party legal proceedings (including actions by government authorities) arising out of or
                      relating
                      to your unlawful use of the services or violation of these terms. This indemnity covers any
                      liability
                      or expense arising from claims, losses, damages, judgments, fines, litigation costs, and legal fees.
                    </li>
                    <li>
                      12ALL.TV won’t be responsible for the following liabilities:
                      <ul>
                        <li>loss of profits, revenues, business opportunities, goodwill, or anticipated savings</li>
                        <li>indirect or consequential loss</li>
                        <li>punitive damages</li>
                      </ul>
                    </li>
                  </ul>
                </section>
              </article>
              <article>
                <h3>Taking action in case of problems</h3>
                <p>
                  Before taking action as described below, we’ll provide you with advance notice when reasonably possible,
                  describe the reason for our action, and give you an opportunity to fix the problem, unless we reasonably
                  believe that doing so would:
                </p>
                <ul>
                  <li>cause harm or liability to a user, third party, or 12ALL.TV</li>
                  <li>violate the law or a legal enforcement authority’s order</li>
                  <li>compromise an investigation</li>
                  <li>compromise the operation, integrity, or security of our services</li>
                </ul>
                <section>
                  <h4>Removing your content</h4>
                  <p>
                    If we reasonably believe that any of your content (1) breaches these terms,(2) violates applicable
                    law,
                    or (3) could harm our users, third parties, or 12ALL.TV, then we reserve the right to stop/block some
                    or
                    all of that content in accordance with applicable law. Examples include child pornography, content
                    that
                    facilitates human trafficking or harassment, and content that infringes someone else’s intellectual
                    property rights.
                  </p>
                </section>
                <section>
                  <h4>Suspending or terminating your access to 12ALL.TV services</h4>
                  <p>
                    12ALL.TV reserves the right to suspend or terminate your access to the services or delete your
                    12ALL.TV
                    Account if any of these things happen:
                  </p>
                  <ul>
                    <li>you materially or repeatedly breach these terms</li>
                    <li>we’re required to do so to comply with a legal requirement or a court order</li>
                    <li>
                      we reasonably believe that your conduct causes harm or liability to a user, third party, or 12ALL.TV
                      — for example, by hacking, phishing, harassing, spamming, misleading others, or scraping content
                      that
                      doesn’t belong to you
                    </li>
                  </ul>
                  <p>If you believe your 12ALL.TV Account has been suspended or terminated in error, you can appeal.</p>
                </section>
              </article>
              <article>
                <h3>Handling requests for your data</h3>
                <p>
                  Respect for the privacy and security of your data underpins our approach to responding to data
                  disclosure
                  requests. When we receive data disclosure requests, our team reviews them to make sure they satisfy
                  legal
                  requirements and 12ALL.TV’s data disclosure policies. One Two All OOD, accesses and discloses data,
                  including communications, in accordance with the laws of Bulgaria, and EU law applicable in Bulgaria.
                </p>
              </article>
              <article>
                <h3>Settling disputes, governing law, and courts</h3>
                <p>For information about how to contact 12ALL.TV, please visit our contact page.</p>
                <p>
                  If you’re a resident of, or an organization based in, the European Economic Area (EEA) or Switzerland,
                  these terms and your relationship with 12ALL.TV under these terms, are governed by the laws of your
                  country of residence, and you can file legal disputes in your local courts.
                </p>
                <p>
                  If you’re a consumer living in the EEA, you may also file disputes regarding online purchases using the
                  European Commission’s Online Dispute Resolution platform, which we accept if required by law.
                </p>
              </article>

              <h2>About these terms</h2>
              <p>
                By law, you have certain rights that can’t be limited by a contract like these terms of service. These
                terms
                are in no way intended to restrict those rights.
              </p>
              <p>
                These terms describe the relationship between you and 12ALL.TV. They don’t create any legal rights for
                other
                people or organizations, even if others benefit from that relationship under these terms.
              </p>
              <p>
                If it turns out that a particular term is not valid or enforceable, this will not affect any other terms.
              </p>
              <p>
                If you don’t follow these terms, and we don’t take action right away, that doesn’t mean we’re giving up
                any
                rights that we may have, such as taking action in the future.
              </p>
              <p>
                We may update these terms (1) to reflect changes in our services or how we do business — for example, when
                we add new services, features, technologies, pricing, or benefits (or remove old ones), (2) for legal,
                regulatory, or security reasons, or (3) to prevent abuse or harm.
              </p>
              <p>
                If we materially change these terms, we’ll provide you with reasonable advance notice and the opportunity
                to
                review the changes, except (1) when we launch a new service or feature, or (2) in urgent situations, such
                as
                preventing ongoing abuse or responding to legal requirements. If you don’t agree to the new terms, you
                should remove your content and stop using the services. You can also end your relationship with us at any
                time by closing your Account.
              </p>
            </main>
          </>
        )
    }
  }

  return (
    <IonCardContent className="terms-content">
      {renderPageText()}
    </IonCardContent>
  )
}

export default TermsContent;
