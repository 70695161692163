import React, {FC, useEffect, useState} from 'react';
import './styles.scss';
import {useTranslation} from 'react-i18next';
import {IonButton, IonButtons, IonIcon, IonItem, IonLabel} from '@ionic/react';
import {peopleOutline, shareSocialOutline} from 'ionicons/icons';
import {Channel, Vlr} from '../../shared/types';
import {getChannelUrlSuffix} from '../../shared/helpers';
import Invite from '../Invite';

type Props = {
  room: Vlr;
};

const RoomActions: FC<Props> = ({room}: Props) => {
  const {t} = useTranslation();
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteUrl, setInviteUrl] = useState('');
  const [numberOfParticipants, setNumberOfParticipants] = useState<string>('1');

  useEffect(() => {
    setNumberOfParticipants(room.participants.length > 99 ? '99+' : `${room.participants.length || 1}`);
  }, [room.participants]);

  const handleShare = (e: any, channel: Channel) => {
    setInviteUrl(`${window.location.origin}${getChannelUrlSuffix(channel)}`);
    setShowInviteModal(true);
  };

  const onInviteClose = () => {
    setShowInviteModal(false);
  };

  return (
    <>
      <IonItem className="room-actions" lines="none">
        <IonItem lines="none" className="number-of-participants">
          <IonIcon icon={peopleOutline} color="dark"  size="small"/>
          <IonLabel>{numberOfParticipants}</IonLabel>
        </IonItem>
        <IonButtons className="room-actions-buttons" slot="end">
          <IonButton onClick={e => handleShare(e, room.channel)}>
            <IonIcon
              slot="icon-only"
              icon={shareSocialOutline}
              color={showInviteModal ? 'success' : 'dark'}
            />
          </IonButton>
        </IonButtons>
      </IonItem>
      <Invite
        title={t('home.share')}
        show={showInviteModal}
        onClose={() => onInviteClose()}
        url={inviteUrl}
      />
    </>
  );
};

export default RoomActions;
