import React, {FC, useEffect, useRef, useState} from 'react';
import './styles.scss';
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPopover,
  isPlatform
} from '@ionic/react';
import {
  chatbubblesOutline,
  cogOutline,
  filmOutline,
  informationCircleOutline,
  micOffOutline,
  micOutline,
  peopleOutline,
  scanOutline,
  shareSocialOutline,
  stop,
  videocamOffOutline,
  videocamOutline
} from 'ionicons/icons';
import {useDispatch, useSelector} from 'react-redux';
import {ReduxSelectors} from '../../../../redux/shared/types';
import UserMediaModal from '../../../../components/UserMediaModal';
import {WebRTCUserMedia} from '../../types';
import Invite from '../../../../components/Invite';
import screen from '../../../../images/icons/screen.svg';
import files from '../../../../images/icons/file.svg';
import myStream from '../../../../images/icons/my-stream.svg';
import myCamera from '../../../../images/icons/my-camera.svg';
import {LivingRoomMode, ShareStreamOption} from '../../enums';
import {VertoLayout} from '../../../../verto/types';
import RoomChangeLayout from '../../../../components/RoomChangeLayout';
import {useTranslation} from 'react-i18next';
import setLivingRoom from '../../../../redux/actions/livingRoomActions';
import ChangeRoomStatus from '../../../../components/ChangeRoomStatus';

type Props = {
  showChat: boolean;
  onShowChat: (value: boolean) => void;
  showDebugStream?: boolean;
  micMuted: boolean;
  camStopped: boolean;
  isFullscreen: boolean;
  onShowParticipants: (value: boolean) => void;
  showParticipants?: boolean;
  onUserMediaChange: (value: WebRTCUserMedia) => void;
  onToggleCam: () => void;
  onToggleMic: () => void;
  onFullscreen: (value: boolean) => void;
  onScreenShare: () => void;
  onStopScreenShare: () => void;
  onChangeStream: () => void;
  onChangeFile: () => void;
  onStopStream: () => void;
  onShowDebugStream?: (value: boolean) => void;
  onLayoutChange: (layout: VertoLayout) => void;
  onShareMyCamera: () => void;
  onSharedOptionChanged: (option: ShareStreamOption) => void;
  setIsSecondaryRecalled: (value: boolean) => void ;
};

const SideBar: FC<Props> = ({
                              showParticipants,
                              showChat,
                              onShowParticipants,
                              onShowChat,
                              showDebugStream,
                              micMuted,
                              isFullscreen,
                              camStopped,
                              onToggleCam,
                              onToggleMic,
                              onShowDebugStream,
                              onUserMediaChange,
                              onFullscreen,
                              onScreenShare,
                              onStopScreenShare,
                              onChangeStream,
                              onChangeFile,
                              onStopStream,
                              onLayoutChange,
                              onShareMyCamera,
                              onSharedOptionChanged,
                              setIsSecondaryRecalled
                            }: Props) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const unreadMessages = useSelector(({unreadMessages}: ReduxSelectors) => unreadMessages);
  const livingRoom = useSelector(({livingRoom}: ReduxSelectors) => livingRoom);
  const {isCoHost, sharingInProgress} = useSelector(({inRoom}: ReduxSelectors) => inRoom);
  const {unableCamTimeout} = useSelector(({webConfig}: ReduxSelectors) => webConfig);
  const {showDebugInfo} = useSelector(({profile}: ReduxSelectors) => profile);

  const init = useRef<boolean>(true);
  const mediaPopover = useRef<HTMLIonPopoverElement>(null);
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const [cam, setCam] = useState<string | null>(null);
  const [openMediaPopover, setOpenMediaPopover] = useState<boolean>(false);

  useEffect(() => {
    // We need this, because if you try to open camera immediately after connecting to the room, instead of the camera, mic is toggled
    setTimeout(() => {
      init.current = false;
    }, unableCamTimeout * 1000);
  }, [unableCamTimeout]);

  const handleToggleMic = () => {
    onToggleMic();
  };

  const handleToggleCam = () => {
    if (!init.current) {
      onToggleCam();
    } else {
      setTimeout(() => {
        handleToggleCam();
      }, 250);
    }
  };

  const handleShowMediaPopover = (event: React.MouseEvent) => {
    mediaPopover.current!.event = event;
    setOpenMediaPopover(true);
  };

  const handleMediaPopoverDismiss = () => {
    mediaPopover.current!.dismiss().then();
  };

  const handleMediaSelect = (option: ShareStreamOption) => {
    handleMediaPopoverDismiss();

    onSharedOptionChanged(option);

    console.log("option",option)

    console.log("livingRoom.singleConnection",livingRoom.singleConnection)

    // if(option === ShareStreamOption.Stream) {
        //   onStopStream();
        // }

    console.log("livingroom share", livingRoom.share)

    if (livingRoom.singleConnection && livingRoom.share !== "file") {
      if (option === ShareStreamOption.Camera) {
        !micMuted && onToggleMic();
        dispatch(setLivingRoom({joinCamMic: true}));
      } else {
        dispatch(setLivingRoom({joinCamMic: false}));
        micMuted && onToggleMic();
      }

      camStopped && onToggleCam();
    }

    switch (option) {
      case ShareStreamOption.Stream:
        setIsSecondaryRecalled(false)
        onChangeStream();
        break;
      case ShareStreamOption.File:
        onChangeFile();
        break;
      case ShareStreamOption.Camera:
        onShareMyCamera();
        break;
      case ShareStreamOption.Screen:
        onScreenShare();
        break;
    }
  };

  const handleStopMedia = () => {
    handleMediaPopoverDismiss();

    switch (livingRoom.share) {
      case ShareStreamOption.Stream:
      case ShareStreamOption.File:
        onStopStream();
        break;
      case ShareStreamOption.Screen:
        onStopScreenShare();
        break;
    }
  };

  return (
    <div className={`living-room-side-bar ${isFullscreen ? 'fullscreen' : ''}`}>
      <IonButtons>
        {
          showDebugInfo && livingRoom.isHost &&
          <>
            <IonButton
              title={t('roomSideBar.streamInfo')}
              onClick={() => onShowDebugStream && onShowDebugStream(!showDebugStream)}>
              <IonIcon
                slot="icon-only"
                icon={informationCircleOutline}
                color={showDebugStream ? 'success' : 'dark'}
              />
            </IonButton>
          </>
        }

        {
          (livingRoom.isHost || isCoHost) &&
          <>
            <ChangeRoomStatus
              isPrivateInitial={livingRoom.mode === LivingRoomMode.Private}
              onChangeRoomStatus={(value) => dispatch(setLivingRoom({mode: value ? LivingRoomMode.Private : LivingRoomMode.Public}))}
            />
            {
              !isPlatform('ios') &&
              <IonButton
                onClick={handleShowMediaPopover}
                title={t('roomSideBar.media')}
              >
                <IonIcon slot="icon-only" icon={filmOutline} color={openMediaPopover ? 'success' : 'dark'}/>
              </IonButton>
            }

            <RoomChangeLayout onLayoutChange={onLayoutChange}/>

            <IonButton
              onClick={() => onShowParticipants(!showParticipants)}
              title={t('roomSideBar.participants')}
            >
              <IonIcon
                slot="icon-only"
                icon={peopleOutline}
                color={showParticipants ? 'success' : 'dark'}
              />
            </IonButton>
          </>
        }

        {
          livingRoom.joinCamMic &&
          <IonButton
            onClick={() => setShowSettingsModal(true)}
            className="side-bar-settings"
            title={t('roomSideBar.settings')}
          >
            <IonIcon
              slot="icon-only"
              icon={cogOutline}
              color={showSettingsModal ? 'success' : 'dark'}
            />
          </IonButton>
        }

        <IonButton
          onClick={() => setShowInviteModal(true)}
          className="invite-button"
          title={t('roomSideBar.share')}
        >
          <IonIcon
            slot="icon-only"
            icon={shareSocialOutline}
            color={showInviteModal ? 'success' : 'dark'}
          />
        </IonButton>

        <IonButton
          onClick={() => onShowChat(!showChat)}
          className="chat-button"
          title={t('roomSideBar.chat')}
        >
          <IonIcon
            slot="icon-only"
            icon={chatbubblesOutline}
            color={showChat ? 'success' : 'dark'}
          />
          <IonBadge
            color="primary"
            className={!showChat ? 'show-delay' : ''}
            hidden={showChat || unreadMessages.accumulator === 0}
          >
            {unreadMessages.accumulator}
          </IonBadge>
        </IonButton>

        {
          (cam !== null ? cam !== 'none' : livingRoom.joinCamMic && livingRoom.cam !== 'none') &&
          <IonButton onClick={handleToggleCam} title={t('roomSideBar.cam')}>
            <IonIcon
              slot="icon-only"
              icon={camStopped ? videocamOffOutline : videocamOutline}
              color={camStopped ? 'dark' : 'success'}
            />
          </IonButton>
        }

        {
          livingRoom.joinCamMic &&
          <IonButton onClick={handleToggleMic} title={t('roomSideBar.mic')}>
            <IonIcon
              slot="icon-only"
              icon={micMuted ? micOffOutline : micOutline}
              color={micMuted ? 'dark' : 'success'}
            />
          </IonButton>
        }

        <IonButton
          onClick={() => onFullscreen(!isFullscreen)}
          className="fullscreen-btn"
          title={t('roomSideBar.fullscreen')}
        >
          <IonIcon
            slot="icon-only"
            icon={scanOutline}
            color={isFullscreen ? 'success' : 'dark'}
          />
        </IonButton>
      </IonButtons>

      <UserMediaModal
        show={showSettingsModal}
        setShow={setShowSettingsModal}
        onSelect={(media: WebRTCUserMedia) => {
          setCam(media.cam);
          onUserMediaChange(media);
        }}
      />

      <Invite
        roomPublicId={livingRoom.publicRoomId}
        showPushInvite={(livingRoom.isHost || isCoHost) && livingRoom.mode !== LivingRoomMode.Private}
        show={showInviteModal}
        url={livingRoom.invitationUrl}
        onClose={() => setShowInviteModal(false)}
      />

      <IonPopover
        ref={mediaPopover}
        isOpen={openMediaPopover}
        onDidDismiss={() => setOpenMediaPopover(false)}
        className="side-bar-media-popover"
        alignment="start"
        side="start"
      >
        <IonList>
          <IonListHeader>{t('roomSideBar.selectMediaToShare')}</IonListHeader>
          <IonItem button onClick={() => handleMediaSelect(ShareStreamOption.Stream)}>
            <IonImg src={myStream} slot="start"/>
            <IonLabel>{t('roomSideBar.myStream')}</IonLabel>
          </IonItem>
          <IonItem button onClick={() => handleMediaSelect(ShareStreamOption.File)}>
            <IonImg src={files} slot="start"/>
            <IonLabel>{t('roomSideBar.file')}</IonLabel>
          </IonItem>
          {
            !livingRoom.joinCamMic &&
            <IonItem button onClick={() => handleMediaSelect(ShareStreamOption.Camera)}>
              <IonImg src={myCamera} slot="start"/>
              <IonLabel>{t('roomSideBar.myCamera')}</IonLabel>
            </IonItem>
          }
          <IonItem button onClick={() => handleMediaSelect(ShareStreamOption.Screen)}>
            <IonImg src={screen} slot="start"/>
            <IonLabel>{t('roomSideBar.shareMyScreen')}</IonLabel>
          </IonItem>
          {
            sharingInProgress &&
            <IonItem button onClick={() => handleStopMedia()}>
              <IonIcon icon={stop} slot="start" color="dark"/>
              <IonLabel>{t('roomSideBar.stop')}</IonLabel>
            </IonItem>
          }
        </IonList>
      </IonPopover>
    </div>
  );
};

export default SideBar;
